.formBox label {
    font-weight: bold;
    margin-right: 10px;
}

.MuiTimelineDot-defaultGrey {
    background-color: #00427e !important;
}

.editBtn {
    position: relative;

    .MuiButtonBase-root {
        position: fixed;
        right: .5rem;
        bottom: .5rem;
        width: 55px;
        height: 55px;
        background-color: #000 !important;
    }
}

body .MuiDrawer-paper .editBtn.MuiButtonBase-root {
    position: unset !important;
}

.activityDetailsSec {
    .branchDiv {
        color: #000;
        font-size: 12px;
        font-weight: bold;
        margin-bottom: -1rem;
        word-break: break-all;

        .MuiGrid-container {
            margin-bottom: 1rem;
        }

        .MuiPaper-root {
            background: #E9F4FC;
            box-shadow: none;
            font-weight: bold;
            width: 75px;
            height: 75px;
            color: #00427E;
            font-size: 16px;
            display: table-cell;
            vertical-align: middle;
            padding:16px 0;
            h3 {line-height:1;}
        }

        h2 {
            font-size: 18px;
            font-weight: bold;
            color: #000;
        }

        ul.MuiList-root {
            margin: 0 !important;
            padding: 0;
        }
    }

    .listItemTextDiv {
        padding-top: 0 !important;

        ul {
            background: transparent;
            padding-top: 0;
        }
    }

    .listItemTextDiv .MuiListItemText-root {
        margin: 1.1rem 0
    }

    .activityDetailsHead {
        background: #E9F4FC;
        box-shadow: none;
        font-weight: bold;
        text-align: left;
        color: #00427E;
        font-size: 14px;
    }

    hr.MuiDivider-root {
        height: 0;
        border: 0;
        border-bottom: 1px solid #ccc !important;
    }

    .MuiGrid-root .lastitem {
        color: #000;
        font-weight: 600;
        word-break: break-all;
    }

    .checkInDiv {
        margin: -15px -5px -20px;

        label {
            margin-top: .5rem
        }

        .MuiGrid-root {
            margin: 0 !important;
            padding: 0;
        }

        b {
            color: #00427E;
        }
    }

    .upcomingSec {
        margin: 0;
        padding: 0;
        background: transparent;
        color: #000;
        font-size: 14px;

        hr.MuiDivider-root {
            border: 0 !important;
        }

        .MuiGrid-root {
            margin: 0 0 0 0;
            padding: 0;
        }

        svg {
            color: #000;
            margin-left: 1rem;
        }

        ul.MuiList-root {
            border: 0;

            li {
                border: 0;
            }
        }
    }

    .historyDiv {
        padding-top: 0 !important;

        .MuiPaper-root {
            border: 0;
            box-shadow: none;
        }

        .MuiTimeline-root {
            margin: -4rem 0 0 -1rem;
        }

        .MuiTimelineDot-root {
            margin: .5rem .5rem .5rem 0;
            padding: 1px;
        }

        .MuiTimelineConnector-root {
            background-color: transparent;
            border-left: 1px dashed #ccc
        }

        .MuiTimelineItem-root {
            float: left;
            text-align: left;
            position: relative;
            min-height: 40px;
            margin-bottom: .5rem;
            &::before {padding:0; margin-left:-6px;}

            .MuiTimelineSeparator-root {
                position: absolute;
                left: 0;
                top: 0;
                min-height:100px;
                width: 1px;
            }
        }
        .MuiTimelineItem-root:last-child {
            .MuiTimelineSeparator-root {min-height:0;}
        }

        label {
            font-size: 12px;
            color: #000;
            display: block;
            line-height: 12px;
        }

        .separator {
            position: absolute;
            left: 0;
            top: 0;
            font-weight: bold;
        }

        .MuiTypography-root {
            flex: 30;
            margin-top: -.15rem;
            margin-left:0;
        }

    }
    table, th, td {
        border:1px solid black;
    }
    .th-header{
        font-weight: bold;
    }
}

.updatePopupDiv {
    li.MuiListItem-root {
        display: inline-block !important;

        svg {
            margin-top: .25rem;
        }
    }

    svg {
        margin-left: 1rem;
        float: right;
    }

    .MuiButtonBase-root {
        border-top: 1px solid #ccc;
        line-height: 3;
        font-weight: bold;
        color: #000;

        .MuiListItemIcon-root {
            color: #000;
        }

        svg {
            margin-left: 0;
        }
    }


    .MuiButtonBase-root:hover {
        color: #00427e;

        .MuiListItemIcon-root {
            color: #00427e;
        }
    }

    button.MuiButtonBase-root {
        box-shadow: none;
    }
}

@media only screen and (max-width:600px) {
    .activityDetailsSec {
        .listItemTextDiv {
            ul {
                margin-top: 0;
                padding-top: 0;
            }
        }
    }

    .checkInDiv {
        .MuiGrid-grid-xs-7 {
            margin: 1rem 0 0 1rem !important;
        }

        label {
            margin-left: 1rem !important;
        }

    }
}
