.preHead {
  display: none !important;
}
.ifli-logo {
  width: 50%;
  height: auto;
}
.loginPage {
  min-height: 100vh;
  background: var(--basicBackgroundColor);
  position: relative;

  .forgotPage {
    height: 100vh;
  }

  .form-check {
    margin: 0 0 0 10px !important;
    input.form-check-input {
      left: 0;
      margin: 3px 0px 0 -4px;
    }
  }

  .loginBox {
    border-radius: 0;
    max-width: 500px;
    padding: 10vh 20px 10px;
    margin: 0 auto;
  }

  .tagLine {
    font-size: 20px;
    text-align: center;
    color: #000;
    font-weight: bold;
    line-height: 26px;
    margin: 30px auto 10px;
  }

  .login-title p.brand {
    padding: 0 80px;
    text-align: center;
    line-height: 35px;
    font-size: 30px;
  }

  .login-title .message {
    text-align: center;
    font-size: 1.25rem;
  }

  .form-display-info {
    text-align: center;
    margin: 20px 0 40px;
    font-weight: bold;
  }

  .form-actions button {
    width: 100%;
  }

  .form-group {
    margin: 0;

    .forgot-password {
      text-align: right;
      margin: 0 10px 0;
      font-weight: normal;
      max-width: 60%;
      float: right;

      a {
        font-size: 14px;
      }
    }
  }

  button {
    background: #000 !important;
    color: var(--primaryColor);
    border: 0;
    padding: 10px !important;
    width: 97%;
    margin: 10px 5px;
    font-size: 16px;
  }

  .inputBox {
    position: relative;
  }

  .icon-input-wrap {
    padding: 0 10px 0 5px !important;
  }

  .icon-input-wrap .fa-envelope,
  .icon-input-wrap .fa-lock {
    top: 11px;
    left: 10px;
  }

  .icon-input-wrap .icon-show-password {
    top: 11px !important;
    right: 5px;
  }

  label {
    display: block;
    font-size: 14px;
    font-weight: normal;
  }

  .text-danger {
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    width: 100%;
    text-align: left;
    margin-bottom: 5px;
  }

  .form-actions {
    padding-left: 20px;
  }
}

.leftColLogin {
  background: url(../../../assets/img/body-BG.png) 0 0 no-repeat;
  background-size: cover;
  text-align: center;
  color: #fff;
  font-family: "whitneybook";

  .leadNurtDiv {
    width: 30vw;
    margin: 17vh auto 0;
    line-height: 24px;
  }

  h3 {
    font-size: 3rem;
    font-weight: normal;
    margin: 1rem 0;
    line-height: 2.75rem;
  }

  h3 span {
    display: block;
    text-transform: uppercase;
    font-size: 1.4rem;
  }
}

.powerDiv {
  color: #000;
  font-size: 0.75rem;
  margin: 0 0 0.5rem;
  position: relative;
  bottom: 0;
  text-align: center;
}

.bottomLinks {
  font-size: 10px;
  margin: 2rem -5px 0;

  .links {
    text-align: right;
    display: block;
    width: 100%;
  }

  a {
    font-size: 10px;
    margin-left: 0.5rem;
  }
}

@media screen and (max-width: 600px) {
  .bottomLinks {
    position: fixed;
    width: calc(100% - 10px);
    left: 5px;
    bottom: 0;
    background: #fff;
    padding: 10px 0;
    margin: 0;

    .links {
      margin: -15px 0 0;
    }
  }

  .loginPage {
    .login-title {
      text-align: center;
    }

    p.brand {
      padding: 0 0 0 15px !important;
    }

    .loginBox {
      padding: 10vh 0px 10px;
    }

    .form-group .forgot-password {
      margin: -28px 10px 0;
    }

    .form-group {
      width: 100% !important;
    }
  }

  .leftColLogin {
    display: none;
  }

  .loginBox {
    max-width: 100% !important;
  }
}

.inputBox .icon-input {
  padding: 5px !important;
}

footer {
  display: none !important;
}

iframe {
  height: auto !important;
}

@media screen and (max-width: 340px) {
  .bottomLinks {
    position: static;
    width: 100%;
    left: 0;
    font-size: 8.5px;
    padding: 15px 0 0;
    display: block;
    margin-right: -10px;

    .links {
      margin: -13px -10px 0 0;
    }

    a {
      font-size: 8.5px;
    }
  }
}
