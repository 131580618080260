.serachResultsDiv {
    h3.title {font-size:18px; font-weight:bold; margin-bottom:.5rem; padding-bottom:.5rem; color:#00427E;
        span {font-size:12px; color:#5D5D5D; margin-left:1rem;}
    }
    .MuiPaper-rounded {margin-bottom:1.5rem; min-height:78vh;
        .MuiGrid-container {margin:0;}
    }
    .listingCard {margin:0;
        label {display:block; font-weight:bold; color:#5D5D5D; margin-bottom:0; padding-bottom:0;}
        .consumer {font-size:16px; color:#000;}
        .icn.rightIcn {float:right;}
        .MuiGrid-root {padding-left:0; margin-left:0; padding-top:0;}
        .MuiGrid-item {border-bottom:1px solid #ccc; padding-bottom:1rem; margin-bottom:1rem;}
        .lastUpdateDiv {font-weight:bold;
            span {margin-right:.5rem;}
        }
                }
            
    .noResultsDiv {text-align:center; transform: translate(-50%,-25%); left:50%; top:50%; position:absolute;
        svg {font-size:64px; margin-bottom:1rem;}
        .MuiTypography-h5 {font-size:16px; font-weight:bold; color:#707070;
            span {display:block; font-size:12px; margin-top:.25rem; font-weight:normal;}
        }
    }
}