// .updateLead {
//     margin: 4vh auto 0; max-width:50%; padding: 20px 0;
//     .form-actions {text-align: right;
//     button {padding: 5px 20px; border-radius: 5px;}
//     }

.updateLead {
    margin: 0 auto 0;
    max-width: 50%;
    padding: 20px 0;
    .formInfo {
        color: #000000;
        font-size: 16px;
        font-weight: bold; 
    }
    .form-actions {
        text-align: right;
    }

    .container.dynamicForm {
        padding: 0;
    }

    .dynamic-form {
        .control3 .md-form.md-outline.autocomplete-wrapper,
        .control2 .md-form.md-outline.autocomplete-wrapper {
            margin: 0;
        }
        .custom-radio {
            .custom-control-label::after {
                content: "";
            }
        }
        .form-actions {
            display: block;
            width: 100%;
            float: none;
            clear: both;
            padding-right: 15px;
        }
    }

    h4 {
        padding-left: 10px;
    }
    p {
        color: #000;
    }
    .buttonBox button {
        width: 30px !important;
        height: 30px !important;
    }
    .control-Location {
        position: relative;
    }
    .locationTrigger {
        position: absolute;
        top: 41px;
        right: 10px;
        color: #0093de;
        cursor: pointer;
    }
}
.accordHead {
    padding: 20px 0 10px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    span {
        font-size: 25px;
        font-weight: bold;
        position: relative;
        right: 5px;
    }
}
.form-actions .btn {
    background: #00427e !important;
}
.form-actions .btn:last-child {
    background: #000 !important;
}
.radioBoxDiv {
    float: right;
    border: 1px solid #b4b4b4;
    height: 28px !important;
    border-radius: 25px !important;

    .react-switch-handle {
        width: 43px !important;
        border-radius: 25px !important;
        height: 24px !important;
        background: #00427e !important;
        z-index: 0 !important;
        margin: 1px 0px 1px 1px;
    }

    div {
        z-index: 0 !important;
        background: transparent !important;
        text-align: center;
        opacity: 1 !important;
    }

    div .switchChecked {
        text-align: left;
        padding-left: 4px;
    }

    div .switchUnChecked {
        text-align: right;
        padding-right: 4px;
    }

    .react-switch-bg {
        z-index: 1 !important;
        line-height: 0.65;
        border: 0;
        color: #9e9e9e;
    }
}
.updateLead .form-title {
    display: none !important;
}

.updateTypeDiv {

    .radioBoxDiv {
        float: right;
        border: 1px solid #b4b4b4;
        height: 28px !important;
        border-radius: 25px !important;
        .react-switch-handle {
            width: 43px !important;
            border-radius: 25px !important;
            height: 24px !important;
            background: #00427e !important;
            z-index: 0 !important;
            margin: 1px 0px 1px 1px;
        }
        div {
            z-index: 0 !important;
            background: transparent !important;
            text-align: center;
            opacity: 1 !important;
        }
        div .switchChecked {
            text-align: left;
            padding-left: 4px;
        }
        div .switchUnChecked {
            text-align: right;
            padding-right: 4px;
        }
        .react-switch-bg {
            z-index: 1 !important;
            line-height: 0.65;
            border: 0;
            color: #9e9e9e;
        }
    }
    .form-group {position:relative;
        .custom-control {
            border: 1px solid #a5a5a5;
            padding: 15px 15px 3px 25px;
            margin-bottom: 5px;
            border-radius: 3px;
            label {
                font-size: 13px;
            }
            .custom-control-label::after {
                top: 0.2rem;
                left: -1.5rem;
            }
        }
        label[for="IsClaimRequired"] {
            font-weight: bold !important;
            width: 47%;
        }
        .custom-control.custom-radio {
            .custom-control-label {
                color: #9e9e9e !important;
            }
            .MuiSvgIcon-root {
                float: right;
                margin: -0.35rem 0 0;
                padding: 0;
                color: #9e9e9e;
            }
        }
        .custom-control.custom-radio.activeRadio {
            border: 1px solid #00427e;
            .custom-control-label {
                color: #00427e !important;
            }
            .MuiSvgIcon-root {
                float: right;
                margin: -0.35rem 0 0;
                padding: 0;
                color: #00427e;
            }
        }

        .custom-control-input:checked ~ .custom-control-label::before {
            background: #00427e;
            border-color: #00427e;
        }
    }

    .form-actions {
        padding-right: 0 !important;
        .savedraftLink {
            font-size: 14px;
            display: inline-block;
            margin: 5px 15px 0 0;
            color: #000000;
        }
        .btn {
            border-radius: 5px;
            padding: 6px 30px;
        }
        .btn.btn-primary {
            background: #000000 !important;
        }
        .btn.cancelBtn {
            background: #00427e !important;
        }
        .btn:last-child {
            margin-right: 0;
        }
    }
}

// .dynamic-form .form-actions {position: fixed; bottom: 0; left: 0; width:100%; background: #fff; padding: 10px;}
//             .dynamic-form .form-actions button {width:43%!important; line-height: 30px; min-width: 120px; font-size: 14px!important;}

//             .dynamic-form .form-actions button {width:43%!important; line-height: 30px; min-width: 120px; font-size: 14px!important;}
@media only screen and (max-width: 767px) {
    .container.updateTypeDiv {padding: 0; margin: 0;}
    .leadList .topMenus {color: #5d5d5d; margin:10px 0 0 0;}
    .updateLead {
        max-width: 100%;
        margin: 0;
        padding: 0 0 60px 5px;

        .dynamic-form .form-actions {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            background: #fff;
            padding: 10px;
            button {
                width: 42% !important;
                line-height: 30px;
                min-width: 120px;
                font-size: 14px !important;
            }
            .btn:last-child {
                margin-right: 15px !important;
            }
        }
        
    }
}
