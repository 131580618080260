.draftSec {
  .labelGroup {
    border: 0;
    padding-bottom: .5rem !important;
  }

  .MuiGrid-item {
    border: 0 !important;
  }

  .checkBoxCol {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 0 !important;
    margin-left: -10px;
    padding-top: 0 !important;
  }

  .rightIcn {
    right: 5px !important
  }

  .delBG {
    background: #ccc;
    border-radius: 50px;
    text-align: center;
    line-height: 27px;
    width: 30px;
    height: 30px;
    display: inline-block;
    padding: 0;
    float: right;
    margin-right: -35px;

    svg {
      color: #00427E;
      font-size: 16px;
    }
  }

  .topDummy {clear:both; padding:0; margin:0; width:100%;
    .MuiGrid-root {margin:0; padding:0;}
    .selectBox {
      float: left;
      background: #fff;
      border: 1px solid #ccc;
      padding: 4px 7px 0 5px;
      line-height: 24px;
      border-radius: 4px;
      margin-bottom:1rem;

      label {
        margin: 0;
        position: relative;
        top: -2px;
      }

      input {
        margin: 0 3px;
      }

      span {
        padding: 1px;
        font-weight: bold;
      }
    }

    .smsBox {
      position: relative;
      top: 1px;
    }

    .dummyLabel {
      float: right;
      right: 5px;
      top: 5px;
      position: relative;
    }
  }

  .MuiPaper-root {
    min-height: 160px;
    padding: 1rem 1rem 0;

  }

  .listingCard h2.MuiTypography-root {
    display: block;
    margin: 0 0 .25rem;
    padding: 0 0 .5rem;
    color: #000;
    border-bottom: 1px solid #ccc;
  }

  .listingCard {
    margin: 0;

    .boxName {
      height: 5.2rem;
      width: 5.2rem;
      background: #E9F4FC;
      border-radius: 10px;
      display: inline-block;
      text-align: center;
      line-height: 5.2rem;
      font-weight: bold;
      font-size: 22px;
    }

    .icnRight {
      float: right;
    }

    .icnDel {
      float: right;
      width: 30px;
      height: 30px;
      line-height: 28px;
      border-radius: 50px;
      background: #ccc;
      text-align: center;
      margin-top: .3rem;

      svg {
        fill: #00427E;
        font-size: 16px;
      }
    }

    label {
      margin: 0;
    }

    .gridLogo {
      padding: 1rem 0 0 0 !important;
    }

    .createDiv {
      padding: 1rem 0 0 !important;
    }

  }

    .smsBox {
      position: relative;
      top: 1px;

      select {
        padding: .35rem;
        border-radius: 4px;
        margin-left: .5rem;
      }

      button {
        background: #fff !important;
        font-weight: bold;
        color: #000 !important;
        border: 1px solid #ccc;
        padding: .35rem .40rem .25rem .75rem;
        border-radius: 4px;
        margin-top: -1px;
        margin-left: 1rem;
      }
    }

    .dummyLabel {
      float: right;
      right: 5px;
      top: 5px;
      position: relative;
    }

}

@media only screen and (max-width:767px) {
  .draftSec {
    .delBG {
      margin-right: 0;
    }

    .listingCard {
      .MuiPaper-rounded {
        min-height: auto;

        .MuiGrid-root.MuiGrid-item {
          margin-left: 0;
        }

        .createDiv {
          margin-left: 0 !important;
          margin-top: 2.3rem
        }
      }

      .icnRight {
        margin-top: -2.5rem;
      }

      .gridLogo {
        padding: 2.5rem 0 0 !important;
      }

      .boxName {
        width: 65px;
        height: 65px;
        line-height: 65px;
        margin: 1rem 0 0 0 !important;
      }

      .labelGroup {
        margin-top: 0;
      }

      .cardTop {
        position: relative;
        left: 30px !important;
        width: 90% !important
      }

      label {
        min-width: 80%;
      }
    }

    .MuiPaper-root {
      padding-right: 0;
    }

    .rightPanel {
      text-align: right !important;
      margin-top: .3rem;
    }

    .checkBoxCol {
      position: absolute;
      left: 0;
      top: 0;
    }

    .rightIcn {
      right: 35px !important;
      top: 0 !important;
    }
  }
}
