.calenderOverviewSec {padding-bottom:0 !important;}
.commonCalendarDashboard {width:100%;
   .MuiGrid-root {background:transparent; padding:0 !important; margin:0; box-shadow:none; width:100%;}
   .MuiPaper-root {background:transparent; box-shadow:none; border:0; margin:0; padding:0;}
   .react-calendar {border:0; padding:0; margin:0;
   .react-calendar__month-view {background: transparent linear-gradient(180deg, #F3F4F7 0%, #CAD9E6 100%) 0% 0% no-repeat}
    .react-calendar__navigation {margin:0; background:#00427E; border-radius:10px 10px 0 0; padding:.5rem 0;}
    .react-calendar__navigation__arrow {width:auto; height:auto; background:transparent !important; font-size:1rem;}
    .react-calendar__navigation__label {background:transparent !important; color:#fff !important; font-size:1rem;}
    .react-calendar__viewContainer button {padding:10px; font-size:.9rem; color:#5D5D5D !important; background:transparent !important;}
    .react-calendar__viewContainer button.react-calendar__tile--active {background:#0093DE !important; color:#fff !important;}
    .react-calendar__navigation button {width:auto;}
    .react-calendar__month-view__weekdays {margin:1rem 0 0; font-size:.75rem;}
    
    
}
.rightCol {width:100%; display:block; max-width:100%; flex:100%;}
.filterBox {margin:1rem 0; padding:0;}
    .calendarHeightScroll {max-height:320px; margin-right:0px; padding-right:5px;}
    
    .cardDate {font-size:14px; color:#000; margin:1rem 0 0;}
    .cardListHeight {
        .MuiTypography-root.MuiTypography-gutterBottom {font-size:.7rem !important;}
        .MuiTypography-root, .cardTime, .cardTimeDuration {font-size:.9rem !important;}
        .typeLabel {padding:2px 10px; font-size:.55rem; border-radius:10px; margin-left:.5rem; background:#F4FBFF;}
        
        .MuiCardContent-root {border:1px solid rgba(204, 204, 204, 0.5); padding:.5rem 1rem; border-radius:10px; margin-top:.3rem;}
        .MuiTypography-root img {height:10px !important; margin-right:-5px; margin-top:0 !important;}
        .cardTimeDuration {margin-left:.5rem;}
        .MuiGrid-root.MuiGrid-item img {margin-top:-12px; height:15px;}
    }
}

