.pydMapDiv{overflow-x:hidden;
    background: #ffffff !important;
    .closeIcon {position:absolute; right:1rem; top:1rem; z-index:99; width:20px; height:20px;
        .MuiSvgIcon-root {font-size:20px; position: relative; z-index:99999; color:#000; display:block !important; font-weight:bold;}
    }
    .checkInDiv {border-radius: 0 !important; position: relative; z-index:99; padding-bottom:1.5rem !important;
        label.MuiInputLabel-root {font-size:14px; font-weight:bold;}
    }
    .planRouteContainer{border-radius:25px 25px 0 0; background: #fff; position: relative;
        z-index:1;  padding:2rem;margin-top: 0;
        h6.MuiTypography-root {font-weight:bold; font-size:18px; margin-bottom:1rem;}
        .dText {font-weight:bold; font-size:12px; float:right; color:#000; width:10rem; margin-top:-2.3rem;
            .MuiSvgIcon-root {position:relative; top:3px; right:0; font-size:12px; color:#00427E; float:left;}
            .MuiOutlinedInput-input {padding:0; font-size:12px; height:auto; font-weight:bold;}
            .MuiOutlinedInput-root {height:auto;}
            .MuiOutlinedInput-notchedOutline {border:0;}
        }
        .tags {
            .MuiTabs-flexContainer {
                button {border-radius:20px; margin-right:.5rem !important; padding:1px 10px !important; border:1px solid #A5A5A5; background:transparent;}
                button.MuiTab-textColorPrimary {background:transparent !important; color:#A5A5A5 !important; padding:1px 10px !important;}
                button.Mui-selected {background:#0093DE !important; border:1px solid #0093DE; color:#fff !important;}
            }
            .MuiTabs-indicator {display:none !important;}
        }
        .listingHeighScroll {max-height:150px; overflow-y:auto;}
        .listingHeighScroll::-webkit-scrollbar {width:7px;}
        .listingHeighScroll::-webkit-scrollbar-track {background:#eee;}
        .listingHeighScroll::-webkit-scrollbar-thumb {border-radius:50px; background:#888;}
        .mapDiv .mapGoogleHeight {height:20vw;
            .gmnoprint {border:1px solid red !important; }
        }
        .dataListContainer {
            div {display:block; width:100%;}
            .listingCard {margin:0;}
            .cardTime {color: #00427E; font-size: 14px; font-weight: bold; padding-left:0; position: relative; top: -1; margin:0rem 0;
                .timeIcon {font-size:13px;}
            }
            .locationIcon {float:left; width:20px; color:#00427E; margin:0 0 0 -.2rem;}
            .sendBG {background:#EAEFF7; padding:8px; font-size:32px; border-radius:50px; color:#00427E;}
            .mapAddressText {font-size:13px; color:#000; width:70%; float:left;height: 35px;
                overflow-y: scroll;}
            .activityText {font-size:12px; font-weight:bold; color:#00427E; 
                .spanActivity {background:#F4FBFF; border:1px solid #0093DE; font-size:8px; padding:2px 8px; border-radius:20px; margin:-.5rem 0 0 .5rem;}
            }
            .MuiPaper-root {border:1px solid #00427E; border-radius:10px 10px 0 0; box-shadow:none; padding:1.5rem 1.5rem .75rem;}
            .arrowRtIcon {position:absolute; top:0rem; right:0rem;cursor: pointer;}
            .title {font-size:14px; font-weight:bold; min-height:36px}
            .updateCallSec {cursor: pointer;border-radius:0 0 10px 10px; border:1px solid #00427E; border-top:0; display:flex; text-align:center; line-height:36px;
                span {cursor:pointer;}
                .MuiGrid-item {font-weight:bold; font-size:12px;}
                .MuiSvgIcon-root {font-size:12px;}
                .MuiGrid-item:first-child {border-right:1px solid #00427E;}
            }
        }
    }
}
@media only screen and (max-width:767px) {
.MuiDrawer-paperAnchorRight {width:100vw; background:transparent !important;}
.MuiBox-root {width:100% !important;}
.mapDiv .mapGoogleHeight {height:64vh !important; margin-top:97px;}
.pydMapDiv {position:relative; background:transparent !important;
    .checkInDiv {bottom:auto !important; top:0;}
    .planRouteContainer {position:fixed; bottom:0; width:100%; padding:1rem 2rem 0;
        .listingHeighScroll {overflow-x:hidden;
            .listingCard {margin-top:.5rem;
                .MuiPaper-root {padding:1rem 1rem .75rem}
            }
        }
        .listingCard .MuiPaper-rounded {min-height:auto !important; padding:1rem 1rem .75rem}
        .tags {
            .MuiTabs-scroller {overflow-x:auto !important;}
        }
        h6.MuiTypography-root {margin-bottom:.5rem; font-size:16px;}
        .dataListContainer .title {min-height:auto;}
    }
}
}