.createActivity {
margin: 0 auto 0; max-width:50%; padding: 20px 0;
.form-actions {text-align: right;}
.form-title {display: none;}

.container.dynamicForm {padding: 0;}

.dynamic-form  {
.control3 .md-form.md-outline.autocomplete-wrapper, .control2 .md-form.md-outline.autocomplete-wrapper {margin: 0;}
        .custom-radio{
            .custom-control-label::after{
                content: '';
            }
        }
.form-actions {display: block; width: 100%; float: none; clear: both; padding-right: 15px;}
}
}

.control-Location {
    position: relative;
    input[type="text"] {padding-right:1.5rem;}
}

.createActivity.updateActivity .form-title{
    display: block !important;
    font-size: 14px;
}




.activityTypeDiv {
    .radioBoxDiv {float:right; border:1px solid #B4B4B4; height:28px !important; border-radius:25px !important;
        .react-switch-handle {width:43px !important; border-radius:25px !important; height:24px !important; background:#00427E !important; z-index:0 !important; margin:1px 0px 1px 1px}
        div {z-index:0 !important; background:transparent !important; text-align: center; opacity:1 !important;}
        div .switchChecked {text-align:left; padding-left:4px;}
        div .switchUnChecked {text-align:right; padding-right:4px;}
        .react-switch-bg {z-index:1 !important; line-height:.65; border:0; color:#9e9e9e;}
    }
    .form-group {position:relative;
        .custom-control {border:1px solid #A5A5A5; padding:15px 15px 3px 25px; margin-bottom:5px; border-radius:3px;
            label {font-size:13px}
            .custom-control-label::after {top: 0.2rem; left: -1.5rem;}
        }
        label[for="IsClaimRequired"] {font-weight:bold !important; width:47%}
        label[for="leadgenerated"] {font-weight:bold !important; width:47%}
        .custom-control.custom-radio {
            .custom-control-label {color:#9e9e9e !important}
            .MuiSvgIcon-root {float:right; margin:-.35rem 0 0; padding:0; color:#9e9e9e;}
        }
        .custom-control.custom-radio.activeRadio {border:1px solid #00427E;
            .custom-control-label {color:#00427E !important}
            .MuiSvgIcon-root {float:right; margin:-.35rem 0 0; padding:0; color:#00427E;}
        }
        .md-form.md-outline {margin:0;}
        .custom-control-input:checked~.custom-control-label::before {background:#00427E; border-color:#00427E}
        .MuiOutlinedInput-root {padding:0; border:0;
            .MuiOutlinedInput-input {padding:5px 4px 0 6px}
        }
        .MuiAutocomplete-endAdornment {
            .MuiButtonBase-root {margin-left:2px;}
        }
        .MuiOutlinedInput-notchedOutline {border:0; margin:0; padding:0;}
    }

    .form-actions {padding-right:0 !important;
        .savedraftLink {font-size:14px; display:inline-block; margin:5px 15px 0 0; color:#000000; font-family:"whitneybook" !important;}
        .btn {border-radius:5px; padding:6px 30px;}
        .btn.btn-primary {background:#000000 !important;}
        .btn.cancelBtn {background:#00427E !important;}
        .btn:last-child {margin-right:0;}
    }
}

.control4 .css-16awh2u-MuiAutocomplete-root{
    height:auto;
    padding: 0;
}

@media only screen and (max-width:767px) {
    .container.activityTypeDiv  {padding: 0;}
    .form-actions {padding: 0!important;
        .savedraftLink {display:none !important;}
        .btn:last-child {margin-right:15px !important;}
    }
    h2 {font-size: 18px!important;}
    .createActivity {max-width: 95%;
        .formBox {padding-bottom: 50px;}
        .dynamic-form .form-actions {position: fixed; bottom: 0; left: 0; width:100%; background: #fff; padding: 10px;z-index: 0;}
        .dynamic-form .form-actions button {width:42vw !important; line-height: 30px; min-width: 120px; font-size: 14px!important;}
    }
    .dynamic-form .form-actions button {width:42vw !important; line-height: 30px; min-width: 120px; font-size: 14px!important;}
}
.dynamic-form{margin-bottom: 100px;}