.editBtn {
    position: relative;
    z-index: 99 !important;
    .MuiButtonBase-root {
        position: fixed;
        right: 20px;
        bottom: 85px;
        width: 50px;
        height: 50px;
        background-color: #00427E !important;
        box-shadow: none;
    }
}

.updatePopupDiv .editBtn .MuiButtonBase-root {
    position: unset !important;
}

.leadDetailsSec {
    .prodList {
        .MuiListItemText-root:first-child {
            border-bottom: 1px solid #ccc;
            margin-bottom: .5rem;
            padding-bottom: .5rem;
        }
    }

    .listPT0 {
        padding-top: 0 !important
    }

    .branchDiv {
        color: #000;
        font-size: 12px;
        font-weight: bold;
        margin-bottom: -1rem;
        word-break: break-all;

        .MuiGrid-container {
            margin-bottom: 1rem;
        }

        .MuiPaper-root {
            background: #E9F4FC;
            box-shadow: none;
            font-weight: bold;
            width: 75px;
            height: 75px;
            color: #00427E;
            padding-top: 25px;
            font-size: 16px;
        }

        h2 {
            font-size: 18px;
            font-weight: bold;
            color: #000;
        }

        ul.MuiList-root {
            margin: 0 !important;
            padding: 0;
        }
    }

    .leadPersonalDiv {
        padding-top: 0 !important;

        .bxSvg {
            display: inline-block;
            text-align: center;
            margin: .5rem .5rem .5rem 0;

            em {
                font-size: 10px;
                font-style: normal;
                display: block;
                text-align: center; word-break:normal;
            }

            svg {
                background: #EFEFEF;
                padding: 6px;
                border-radius: 50px;
                fill: #00427E;
                font-size: 34px;
                margin: 0 auto;
            }

        }

        .bxSvg.activeClass {
            svg {
                fill: #ccc;
            }
        }
    }

    .listItemTextDiv {
        padding-top: 0 !important;

        ul {
            background: transparent;
        }
    }

    .listItemTextDiv .MuiListItemText-root {
        margin: 1.1rem 0
    }

    .activityDetailsHead {
        background: #E9F4FC;
        box-shadow: none;
        font-weight: bold;
        text-align: left;
        color: #00427E;
        font-size: 14px;
    }

    hr.MuiDivider-root {
        height: 0;
        border: 0;
        border-bottom: 1px solid #ccc !important;
    }

    .MuiGrid-root .lastitem {
        color: #000;
        font-weight: 600;
        word-break: break-all;
    }

    .listWidth {
        width: 100%;
    }

    .checkInDiv {
        margin: -15px -5px -20px;

        label {
            margin-top: .5rem;
        }

        .MuiGrid-root {
            margin: 0 !important;
            padding: 0;
        }

        b {
            color: #00427E;
        }
    }

    .upcomingSec {
        margin: 0;
        padding: 0;
        background: transparent;
        color: #000;
        font-size: 14px;

        hr.MuiDivider-root {
            border: 0 !important;
        }

        .MuiGrid-root {
            margin: 0 0 0 0;
            padding: 0;
        }

        svg {
            color: #000;
            margin-left: 1rem;
        }

        ul.MuiList-root {
            border: 0;

            li {
                border: 0;
            }
        }
    }

    .historyDiv {
        padding-top: 0 !important;

        .MuiPaper-root {
            border: 0;
            box-shadow: none;
        }

        .MuiTimeline-root {
            margin: -4rem 0 0 -1rem;
        }

        .MuiTimelineDot-root {
            margin: .5rem .5rem .5rem 0;
            padding: 1px;
        }

        .MuiTimelineConnector-root {
            background-color: transparent;
            border-left: 1px dashed #ccc
        }

        .MuiTimelineItem-root {
            float: left;
            text-align: left;
            position: relative;
            min-height: 40px;
            margin-bottom: .5rem;

            .MuiTimelineSeparator-root {
                position: absolute;
                left: 0;
                top: 0;
                min-height: 67px;
                width: 1px;
            }
        }

        label {
            font-size: 12px;
            color: #000;
            display: block;
            line-height: 12px;
            max-width:135px;
        }

        .separator {
            position: absolute;
            left: 0;
            top: 0;
            font-weight: bold;
        }

        .MuiTypography-root {
            flex: 30;
            margin-top: -.15rem;
            margin-left: 0rem;
        }

    }
}

.updatePopupDiv {
    li.MuiListItem-root {
        display: inline-block !important;
    }

    svg {
        margin-left: 1rem;
        float: right;
    }

    .MuiButtonBase-root {
        border-top: 1px solid #ccc;
        line-height: 3;
        font-weight: bold;
        color: #000;

        .MuiListItemIcon-root {
            color: #000;
        }

        svg {
            margin-left: 0;
        }

        .editButton {
            width: 40px;
            height: 40px;
        }
    }

    .MuiButtonBase-root:hover {
        color: #00427e;

        .MuiListItemIcon-root {
            color: #00427e;
        }

    }

    .MuiButtonBase-root.editButton {
        background: #00427E !important;
    }

    button.MuiButtonBase-root {
        box-shadow: none;
    }

}


.mapDiv {
    background: #ccc !important;
    height: calc(100% - 10px);
    width: 30rem;

    .mapGoogleHeight {
        height: 29.6vw;
    }

    .mapSize {
        border: 1px solid blue
    }

    .btnBx {
        margin: 1.5rem 0 0;

        button {
            width: 48%;
            margin: 0 .5rem;
            padding: 3px 8px;
            font-size: 12px;
            text-transform: uppercase !important;
        }

        button:nth-child(1) {
            margin: 0;
        }

        button:nth-child(2) {
            float: right;
            margin: 0;
            background: #000 !important;
        }
    }

    .MuiOutlinedInput-root {
        height: 30px;

        svg {
            margin: -7px -5px 0 0;
            color: #00427E;
            font-size: 13px;
        }
    }

    fieldset legend {
        float: right !important;
    }

    .srch {
        position: relative;

        .srchIcon {
            position: absolute;
            left: .45rem;
            top: .43rem;
            z-index: 2;
            color: #ccc;
            font-size: 16px;
        }
    }

    .checkInDiv {
        padding: 2.5rem 3rem 3rem 2.5rem;
        border-radius: 15px 15px 0 0;
        background: #fff;
        position: relative;
        z-index: 1;

        p {
            font-size: 18px;
            font-weight: bold;
            margin: 0 0 .75rem;
            padding: 0;
        }

        label {
            margin-bottom: .75rem;
            display: block;
            font-size: 12px;
        }

        .MuiInputBase-input {
            padding: .5rem 1rem .75rem 1.85rem;
            font-size: 13px;
            -webkit-text-fill-color:rgba(0, 0, 0, 0.8);
        }
    }
}


@media only screen and (max-width:800px) {
    .mapDiv {width:100%;}
}

@media only screen and (max-width:600px) {
    .leadPersonalDiv {
        padding-top: 24px !important;

        ul {
            margin-top: 1rem !important;
            padding-top: 0 !important;
            background: transparent;
        }
    }

    .editBtn {
        .MuiButtonBase-root {
            bottom: 5.7rem;
        }
    }

    .mapDiv {
        .checkInDiv {
            position: fixed;
            bottom: -2px;
            left: 0;
            width: 100%; padding:1.5rem 3rem 1rem !important;
            label.MuiInputLabel-root {margin-left:0 !important;}
        }
    }
}