.teamListDiv {
    .arrowIcon {font-size:20px; cursor:pointer; position:absolute; top:1rem; right:1rem;}
    .MuiPaper-root {padding:1rem; width:100%; display:block; margin-bottom:1rem; position:relative;}
    .profBG {width:80px; height:80px; background:#E9F4FC; border-radius:5px; text-align:center; line-height:80px; font-size:20px; font-weight:bold; color:#00427E;}
    h3 {font-size:16px; color:#000; font-weight:bold; margin:.5rem 0 .75rem;}
    .loggedDiv {color:#000;
        .onlineCircle {background:#08C400; border-radius:50px; width:10px; height:10px; display:block; margin:-11px 0 0 -14px;}
        .offlineCircle {background:#ED1C24; border-radius:50px; width:10px; height:10px; display:block; margin:-11px 0 0 -14px;}
    }
    .loggedDiv, .lastSeenDiv {font-size:14px; font-weight:bold;}
    .lastSeenDiv {margin-left:14px;}
}

.teamDiv {
    h3 {margin:.5rem 0 .5rem;}
    .MuiGrid-item.MuiGrid-grid-xs-8 {padding-left:0;}
    .loggedDiv {float:left;
        .offlineCircle {float:left; margin:0.5rem .5rem 0 0;}
    }
    .lastSeenDiv {clear:both; margin:0;}
}
@media only screen and (max-width:600px) {
    .teamDiv {
        .MuiGrid-item.MuiGrid-grid-xs-8 {padding-left:.5rem;}
    }
}