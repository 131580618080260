@import '../../styles/variables.css';

.custom-footer-wrapper {
  background-color: var(--secondaryBackgroundColor) !important;
  color: var(--primaryColor) !important;
}
.unique-color-dark{
  background-color: var(--secondaryBackgroundColor) !important;
}
footer.page-footer {
  background-color: var(--secondaryBackgroundColor) !important;
}
.custom-copyright {
  background-color: var(--secondaryBackgroundColor) !important;
  color: var(--primaryColor) !important;
  border-top: 1px solid var(--primaryColor);
}
.circle-icon {
  background: var(--primaryColor);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  vertical-align: middle;
  padding: 30px;
}
span::after {
  content: " ";
}
.fa-inverse {
  color: black !important;
}
.ftr-lnk {
  color: var(--primaryColor) !important;
  font-size: 13px;
  display: block;
}
