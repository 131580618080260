.leadList {
  .filterBox {
    margin-top: 48px;
  }

  .MuiPaper-rounded {
    border-radius: 10px;
    padding: 5px !important;

    .checkBoxCol {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      padding-left: 0;
      margin-left: -10px;
      margin-right: 10px;
    }

    .MuiGrid-container {
      padding: 0;
    }
  }

  .labelGroup span {
    color: #000 !important;
  }

  .labelGroup {
    border-top: 1px solid #f3f5f7;
    padding-top: 7px !important;
    color: #000;
    margin-bottom: 0.3rem;

    label {
      color: #5d5d5d !important;
      font-size: 13px;
      font-weight: normal !important;
      margin-bottom: 0;
    }

    p {
      font-size: 1rem;
    }

    .MuiGrid-item {
      padding: 0 10px !important;
      max-width: 20%;
    }

    .consumer {
      color: #00427e !important;
    }

    .MuiGrid-item.premium span {
      color: #1398dc !important;
    }

    .content {
      font-weight: normal;
      font-size: 14px;
      font-style: italic;
    }

    .MuiGrid-item:nth-child(4),
    .MuiGrid-item {
      border-right: 1px solid #f3f5f7;
    }

    .MuiGrid-item:nth-child(5) {
      border: 0;
    }
  }

  .labelGroup.labelWidLessDiv {

    .MuiGrid-item:nth-child(1),
    .MuiGrid-item:nth-child(2) {
      max-width: 10%;
    }

    .MuiGrid-item:nth-child(5) {
      border-right: 1px solid #f3f5f7;
    }

    .MuiGrid-item:nth-child(6) {
      border: 0;
    }
  }

  .buttonBox {
    text-align: right;
    margin: 0.4rem 0 0;
    padding-bottom: 0 !important;
    padding-bottom: 0px !important;

    em {
      font-size: 12px;
      font-style: normal;
    }
  }

  .branchName {
    font-size: 18px;
    padding-left: 5px;
    font-weight: bold;
    color: #000;
    overflow: auto;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 40px;
    min-height: 50px;
  }

  .hotClass {
    display: inline-block;
  }

  .listingCard h2 {
    display: inline;
  }

  .listTitle {
    display: inline-block;
    float: left;
  }

  .topDummy {
    .selectBox {
      float: left;
      background: #fff;
      border: 1px solid #ccc;
      padding: 4px 7px 0 5px;
      line-height: 24px;
      border-radius: 4px;
      margin-bottom: 0.5rem;

      label {
        margin: 0;
        position: relative;
        top: -2px;
      }

      input {
        margin: 0 3px;
      }

      span {
        padding: 1px;
        font-weight: bold;
      }
    }

    .smsBox {
      position: relative;
      top: 1px;
    }

    .dummyLabel {
      float: right;
      right: 5px;
      top: 5px;
      position: relative;
    }
  }

  .MuiPaper-root {
    padding: 10px;
  }

  .gridLogo {
    background: none !important;
    color: #00427e !important;
    max-height: 30%;
    padding: 0 !important;
    display: flex;

    h2 {
      background: #e9f4fc !important;
      border-radius: 5px;
      align-self: center;
      display: flex;
      padding: 0;
      position: relative;
      left: -25px;
      width: 90%;
      height: 70%;
      text-align: center;
      justify-content: center;
      align-items: center;
      top: 0px;
      font-weight: bold;
    }
  }

  .mobIcons {
    display: none;
  }

  .gridBG {
    position: relative;
    top: -20px;
  }

  .topMenus {
    margin-bottom: 10px;
  }

  .icnNo {
    background: none;
    color: red;
  }

  .cardTop h2 {
    margin-left: -25px;
    margin-top: 0.15rem;
    display: inline-block;
    padding-bottom: 0;
  }

  .icnFilterBox {
    position: fixed;
    bottom: 80px;
    right: 10px;
    background-color: #00427e;
    color: #fff;
    border-radius: 50%;
    padding: 12px;
    z-index: 99;
  }

  .collateral_link {
    z-index: 1;
    // cursor: pointer;
    font-size: medium;
    font-weight: bold;
    // color: black;
  }

  @media only screen and (min-width: 1400px) {

    .collateral {
      position: absolute;
      top: 1rem;
      left: 34rem;
      font-size: medium;
      // color: black;
      font-weight: bold;
      // cursor: pointer
    }
  }

  @media only screen and (max-width: 801px) {
    .editBtn {
      z-index: 999;
    }

    .buttonBox .icn.rightIcn {
      top: -128px !important;
      right: 0px !important;
    }

    label {
      font-size: 16px !important;
    }

    .hotClass {
      width: 100%;
      position: relative;
      left: -13px;
      float: left;
    }

    .topMenus .topDummy {
      margin-bottom: 10px;

      .MuiGrid-item:last-child {
        margin: 0;

        .rightPanel {
          position: relative;
          top: 10px;
          right: 0;
        }
      }
    }

    .topMenus .topDummy .MuiGrid-grid-xs-6:first-child {
      float: left;
      width: 70%;
      max-width: 70% !important;
    }

    .topMenus .topDummy .MuiGrid-grid-xs-6:nth-child(2) {
      float: right;
      width: 30%;
      max-width: 30% !important;
    }

    .tabBox button.MuiButtonBase-root {
      padding: 7px 50px !important;
      min-width: 120px;
      font-size: 1.3rem;
    }

    .tabBox {
      text-align: center;
    }

    .buttonBox {
      position: absolute;
      top: 110px !important;
      right: 30px !important;

      .icn {
        display: none;
      }
    }

    .listingCard {
      width: 100%;

      .labelGroup {
        padding: 10px 0 15px !important;
        margin-top: 0 !important;
      }

      .labelGroup .MuiGrid-root.MuiGrid-item {
        min-height: 37px;
        border: 0 !important;
        padding: 0 !important;
      }

      .checkBoxCol {
        position: absolute;
        top: -112px;
        left: 10px;
      }

      .checkBoxCol.campaignBox {
        top: -135px;
      }

      .gridLogo {
        position: relative;
        left: -20px;
      }

      .cardTop h2 {
        margin-left: -25px;
        padding-top: 0;
        margin-top: -4px;
      }
    }

    .leadList {
      padding-left: 20px;
    }

    .topDummy {
      margin: 0;
    }

    .topDummy .MuiGrid-item {
      padding: 0;
    }

    .gridLogo h2 {
      position: absolute;
      left: 30px;
      width: 60px;
      height: 60px;
      font-size: 20px !important;
      font-weight: bold;
      top: -70px;
    }

    .mobIcons {
      border-top: 1px solid #ccc;
      padding-top: 10px;
      position: absolute;
      bottom: 0;
      right: 20px;
      width: 94%;
      display: flex;
      justify-content: center;
      align-items: baseline;
      font-size: 9px;
      text-align: center;
      z-index: 0;

      .icn {
        min-width: 70px;
        z-index: 999;
      }

      em {
        display: block;
      }
    }

    .MuiGrid-item {
      max-width: 100% !important;
      padding: 0 0 0 5px;
    }

    .MuiGrid-item.mobileTitle {
      padding: 15px 0 0 10px !important;
    }

    .MuiGrid-item:last-child {
      margin-bottom: 10px;
    }

    .MuiPaper-rounded .MuiGrid-container {
      margin-left: 0;
      padding-bottom: 25px;
      // padding-top: 140px;
      width: calc(100% - -9px);
    }

    .mobHeader {
      display: block;
      width: 100%;
    }

    .cardTop {
      position: absolute;
      right: 0;
      width: 83% !important;
      top: -65px;
      left: 90px;
    }

    .rightIcn {
      right: -20px !important;
    }

    .rightIcn,
    .icnUpdate {
      position: absolute;
      top: 0;
      right: 0;
      background: none;
      display: block !important;
    }

    .icnUpdate {
      right: 20px;
      top: 4px;
    }

    .listingCard br {
      display: none;
    }

    .listingCard label,
    .listingCard p {
      min-width: 46%;
      float: left;
    }

    .listingCard .makeStyles-activityLogo-40 {
      display: none;
    }

    .mobileResponsive {
      margin-top: 110px;
    }

    .collateral {
      margin-left: 5rem;
      margin-top: 1rem;
      // color: black;
      font-weight: bold;
      // cursor: pointer;
    }
  }

  .rightPanel {
    position: relative;
    top: 10px !important;
    right: 10px;
  }
}

.leftCol {
  .topDummy {
    clear: both;
    padding: 0;
    margin: 0;
    width: 100%;

    .MuiGrid-root {
      margin: 0;
      padding: 0;
    }

    .selectBox {
      float: left;
      background: #fff;
      border: 1px solid #ccc;
      padding: 4px 7px 0 5px;
      line-height: 24px;
      border-radius: 4px;
      margin-bottom: 0;
      position: relative;
      z-index: 1;

      label {
        margin: 0;
        position: relative;
        top: -2px;
      }

      input {
        margin: 0 3px;
      }

      span {
        padding: 1px;
        font-weight: bold;
      }
    }

    .smsBox {
      position: relative;
      top: 1px;
    }

    .dummyLabel {
      float: right;
      right: 5px;
      top: 5px;
      position: relative;
    }
  }
}

.pehlaKadamList {

  .labelGroup.labelWidLessDiv .MuiGrid-item:nth-child(1),
  .labelGroup.labelWidLessDiv .MuiGrid-item:nth-child(2) {
    max-width: 25%;
  }

  .branchName {
    min-height: auto;
  }
}

@media only screen and (max-width: 600px) {
  .leadList .mobIcons {
    right: 5px;
  }

  .leftCol .topDummy .selectBox {
    margin-left: 6px;
  }

  .leadList {
    .MuiPaper-root {
      min-height: 400px;
    }

    .MuiPaper-rounded .MuiGrid-container {
      margin-left: 0;
      width: calc(100% - 11px);
    }

    .buttonBox .icn.rightIcn {
      top: -130px !important;
      right: 5px !important;
    }

    .cardTop {
      width: 74% !important;
    }

    .hotClass {
      top: -14px;
    }
  }

  .leadHeightMobile {
    .MuiPaper-root {
      min-height: 320px;
    }
  }
}
