

  .activityList .icn.rightIcn {position: absolute;
    right:15px;
    top: -7px;}
.listingCard, .activityList {margin:1rem 15px 0 0;
  .cardBoxAcivity {
    .cardTop .MuiGrid-root {padding-bottom:0;}
      h2.boxName {background:#E9F4FC; width:100px; height:100px; line-height:100px; font-weight:bold; border-radius:10px; display:inline-block; color:#00427E;}
    }
  .timeStamp {font-style:italic;}
  .MuiPaper-rounded {border-radius: 10px;}
  .labelGroup span {color: #000!important;}
  .labelGroup label {color:#5D5D5D!important; font-size:1rem; font-family: "whitneymedium";}

  .labelGroup  {border-top:1px solid #F3F5F7; padding-top: 7px; color: #000;}

  .labelGroup .MuiGrid-item {border-right:1px solid #F3F5F7; font-size:14px;}
  .labelGroup .MuiGrid-item:nth-child(4), .labelGroup .MuiGrid-item:nth-child(5) {border:0}
  .buttonBox {text-align: right;}
  .branchName{font-size:14px; padding-left:15px; font-family: "whitneymedium" !important;}
  .subHeadBlue span {color:#00427E !important;}
    .icnFilterBox {position: fixed; bottom:72px; right:12px; background-color: #00427E; color: #fff; border-radius: 50%; padding:12px; z-index: 99;
      svg {font-size:25px;}
    }
  @media only screen and (max-width:767px) {
    .MuiPaper-rounded {min-height: 260px;
    .labelGroup {border-bottom:0; border-right:0; margin-top:15px; }
    }
    .icnFilterBox {position: fixed; bottom:72px; right:1.65rem; background-color: #00427E; color: #fff; border-radius: 50%; padding:12px; z-index: 99;
      svg {font-size:25px;}
    }
    .activityList .icnFilterBox {right:23px}
    .buttonBox .icn.rightIcn {
      position: absolute;
      right:55px!important;
      top: -3px!important;
  }
    

  .buttonBox {position: absolute;
    top: 4px!important;
    right: -4px!important; left: auto!important;}
    .tabBox {text-align: center;}
    .topMenus .rightPanel {text-align: right!important; padding-right: 1px; color: #5D5D5D; font-family: "whitneymedium";}
    .timeStamp  {font-style: italic;}
    .mobHeader {display: block; width:100%}
      .cardTop {    position: absolute;
        right: 0;
        width: 83%!important;
        top: 0;
        left: 70px;}
        .rightIcn {right:-20px!important}
    .rightIcn, .icnUpdate {position:absolute; top:0; right:0; background: none; display: block!important;}
    .icnUpdate  {right:20px; top:4px}

    .listingCard br {display: none;}
    .listingCard label {min-width: 45%;}
    .listingCard .makeStyles-activityLogo-40 {display: none;}
    }


  }

@media only screen and (max-width:767px) {
  .activityList .cardBoxAcivity h2.boxName {width:60px; height:60px; line-height:65px; margin:-8px 0 0 -10px;}
  .activityList {padding-top: 0; margin-right:0;
    .cardBoxAcivity {
      .cardTop .MuiGrid-root {padding-left:30px; padding-top:20px;}
    }
  }
  .listingCard {margin-right:0;}
  .activityList .listingCard label {float:left;}
  .reset{
    margin:4px 20px 3px!important;
  }
  }
  .activityFilters, .leadFilters{
    padding:0 !important;
    #submitButton{
      margin:0;
      padding:6px 15px !important;
    }
    .reset{
      float:right;
      margin:4px 0 3px;
      cursor: pointer;
    }
    .activityFiltersMobile, .leadFiltersMobile {
      .control7{
        margin-bottom: 52px;
      }
    }

  .MuiAutocomplete-root {height:auto; padding-bottom:0;
    .MuiFormControl-root {margin:-6px -10px 0 -12px; width:calc(100% - -24px); min-height:40px; 
      .MuiOutlinedInput-root {padding-top:5px; padding-bottom:0; }
      .MuiOutlinedInput-notchedOutline {border:0}
    }
  }
   
  }
  .leadFilters form.dynamic-form {
    .form-group {
      .md-form {margin-top:0;}
    }
  }

  @media only screen and (max-width:600px) {
    .activityList {
      .cardBoxAcivity {
        .cardTop .MuiGrid-root {padding-left:10px;}
      }
    }

  }