body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

.progress {
	background-color: #d8d8d8;
/* 	border-radius: 20px; */
	position: relative;
	margin: 15px 0;
	height: 30px !important;
	width: 250px;
	display: flex;
/* 	align-items: center; */

}

.progress-done {
	background: linear-gradient(to left,#013355, #0a7eeb);
	box-shadow: 0 3px 3px -5px #013355, 0 2px 5px #013355;
/* 	border-radius: 20px; */
	color: #fff;
	display: flex;
	align-items: center;
	height: 100%;
	width: 0;
	opacity: 0;
	transition: 1s ease 0.3s;
}

.progress-res{
	/* position:relative; */
	top:100%;
	left:-10px;
	margin-top:15px !important;
}