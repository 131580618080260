:root {
    --primaryBackgroundColor: #000;
    --secondaryBackgroundColor: #00427E;
    --primaryColor: #fff;
    --basicBackgroundColor: #fff;
    --basicColor: #000;
    --anchorColor: #0D3F7B;
    --fontSizeHead1: 30px;
    --fontSizeHead2: 1.25rem;
    --fontLarge: 16px;
    --fontMedium: 14px;
    --fontSmall: 12px;
/* Primary colors  */
    --primaryRed: #BA2A22;
    --primarySky:#5393DB;
    --primaryOrange:#CB6F24;
    --primaryBlue:#00427E;
    --primaryGray:#646666;
/* Secondary colors  */
    --secondryWht: #ffffff;
    --secondryLight:#f6f6f6;
    --secondryGray:#848687;
    --secondryBlack:#010101;
    --secondryBlue:#013355;
    --secondrySky:#026CB6;

    /* font families and sizes  */
    --pageHeading: #5d5d5d; font-family:"whitneymedium";
    --sectionHeading:#5d5d5d; font-family:"whitneymedium";
    --cardHeadings:#5d5d5d; font-family:"whitneymedium";
  }