.react-calendar {
    width: 100% !important;
    font-size: 1.4rem;
    font-weight: bold;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 2rem;

    abbr[title] {
        text-decoration: none;
    }

    .react-calendar__viewContainer {

        input[type="button"],
        button,
        .btn-primary {
            background: #fff !important;
            color: #000 !important;
            padding: 40px 10px;
            border-radius: 5px;
            font-size: 1.3rem;
        }

        .react-calendar__tile--active {
            background: #00427e !important;
            color: white !important;
            border-radius: 5px;
            font-weight: bold;
        }
    }

    .react-calendar__month-view__weekdays {
        margin: 1rem 0 2rem
    }

    .react-calendar__navigation {
        height: auto;
        margin: 2rem 1.7rem 3rem 1.7rem;

        button {
            font-weight: bold;
        }
    }

    .react-calendar__navigation__arrow {
        width: 50px;
        height: 50px;
        background: #ccc !important;
        border-radius: 50px;
        margin: 0 .5rem
    }

    .react-calendar__navigation__label {
        background: #fff !important;
        color: #00427e !important;
    }

    .react-calendar__tile {
        font-weight: bold;
    }


}
.rightCol {width:100%; display:block !important;}
.filterBox {width:100%; margin:0;}
.icn.rightIcn {
    margin: -6px 0 6px !important;
    padding: 0;
}
.calendarHeightScroll {max-height:720px; overflow-y:auto; margin-right:0px; padding-right:5px;}
.calendarHeightScroll::-webkit-scrollbar {width:7px;}
    .calendarHeightScroll::-webkit-scrollbar-track {background:#eee;}
    .calendarHeightScroll::-webkit-scrollbar-thumb {border-radius:50px; background:#888;}
.cardDate {
    font-weight: bold;
    font-size: 14px;
    margin: 0 0 .5rem;

    .taskDiv {
        float: right;
        font-weight: normal;
        font-size: 12px;
    }
}

.cardListHeight {
    position: relative;
    margin-bottom: 5px;
}

.cardTime {
    color: #00427E;
    font-size: 14px;
    font-weight: bold;
    padding-left: 10px;
    position: relative;
    top: -1;
}

.cardTimeDuration {
    color: #A5A5A5;
    font-size: 14px;
    font-weight: bold
}

.typeLabel {
    color: #0093DE;
    text-align: left;
    font: normal 7px/19px Whitney;
    font-size: 10px;
    border: 1px solid blue;
    border-radius: 4px;
    padding: 5px 3px;
}

.calendarMob {/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f3f4f7+0,cad9e6+100 */
    background: #f3f4f7; /* Old browsers */
    background: -moz-linear-gradient(top,  #f3f4f7 0%, #cad9e6 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #f3f4f7 0%,#cad9e6 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #f3f4f7 0%,#cad9e6 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3f4f7', endColorstr='#cad9e6',GradientType=0 ); /* IE6-9 */
    border-radius:0 0 10px 10px; padding:.5rem 1rem 1rem .5rem;
    .arrowBackIcon {background:#cad8e6; border-radius:50px; text-align:center; margin-top:2.5rem; width:25px; height:25px; line-height:20px; display:block;
        svg {font-size:14px; font-weight:bold; color:#fff;}
    }
    .arrowNextIcon {background:#cad8e6; border-radius:50px; text-align:center; margin-top:2.5rem; width:25px; height:25px; line-height:20px; display:block;
        svg {font-size:14px; font-weight:bold; color:#fff;}
    }
    .col {padding:10px 0; font-size:16px; font-weight:bold;}
    .cell.selected {background:#0093DE !important; border-radius:15px;}

}

@media only screen and (max-width:767px) {
    .react-calendar {padding:0;
        .react-calendar__navigation {margin:1.5rem 0;}
        .react-calendar__navigation__arrow {width:30px; min-width:30px; height:30px; background:#000 !important; display:inline-grid;}
        .react-calendar__viewContainer button {padding:20px 10px;}
        .react-calendar__viewContainer .react-calendar__tile--active {background:#0093DE !important;}
        .react-calendar__month-view__weekdays {margin:1rem 0;}
    }
    .cardListHeight {
        .icn.rightIcn {margin:-15px 30px 0 0 !important}
    }
}