.calendar .body {
    .cell.today,  .cell.selected {
    background-color: #ff1a79;
   color:#fff;
  }
}

  .days.row{
      color:rgba(0, 0, 0, 0.87);
      text-transform: uppercase;
  }
  .header{
    background: #00427E;
    border-radius: 10px 10px 0 0;
    padding:10px;
    color: #fff;
  }