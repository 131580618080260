.notificationPopupDiv {background:#F3F5F7; padding:5rem 0 0; position:relative;
    .readBtn {border-radius:3px; padding:.25rem .5rem; margin-left:.5rem; display:inline-block;}
    .seeMoreBtn {border-radius:3px; padding:.25rem .5rem; display:inline-block;}
    .closeIcon {position:absolute; top:1rem; right:1rem; cursor:pointer;
        svg {font-size:18px; font-weight:bold;}
    }
    h3.title {font-size:18px; font-weight:bold; margin:0 1rem 0 2rem; padding-bottom:1rem; color:#00427E; position:relative;
        span {font-size:10px; color:#5D5D5D; float:right; margin:.5rem 0 0;}
    }
    h3.title::before {content:""; position:absolute; border-bottom:2px solid #00427E; width:102px; bottom:0; left:-2px;}
    .notifInner {background:#fff; padding:1rem;
        .listItem {border:1px solid #ccc; padding:.65rem; border-radius:10px; margin-bottom:.75rem; overflow:hidden;
            .arrowIcon {float:right; font-size:20px; cursor:pointer;}
            h6 {font-size:12px; font-weight:bold;}
            .statusText {font-size:14px; color:#000;}
            svg {font-size:14px; margin-top:-2px;}
            .lastDate {font-size:12px; margin:.5rem 0;
                span {font-size:10px; float:right;}
            }
            .updateEdit {margin:0 -.7rem -.3rem; width:107%; padding-top:.2rem; border-top:1px solid #ccc;
                .MuiGrid-root {text-align:center; margin:-.3rem 0; padding:.5rem 0; border-left:1px solid #ccc; }
                .MuiGrid-root:first-child {border:none}
                svg {margin-right:.5rem;}
                a.MuiTypography-root {text-align:center; margin:.2rem 0 0; padding:.5rem; text-decoration:none; font-size:14px; color:#000; font-weight:bold;}                
            }
        }
    }
}
.notificationPopupDiv.notifiPage {padding:1rem 0 0;
    h3.title {margin:0;
    &::before {border:0;}
    }
    .notifInner {padding:1.5rem 1.35rem .5rem; border-radius:5px; border:1px solid #eee}
}

@media only screen and (max-width:600px) {
    .notificationPopupDiv {
        .notifInner {
                a.MuiTypography-root {font-size:12px !important;}   
        }
    }
} 