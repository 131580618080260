.admindashCompain {
  .btn {
    padding: 6px 15px !important;
    background: #00427E !important;
  }

  .MuiToolbar-root .MuiButtonBase-root.MuiIconButton-root {
    padding: 5px;
    margin: 0 .5rem
  }

  .MuiTypography-h6 {
    font-weight: bold;
    font-size: 16px;
  }

  .MuiToolbar-gutters {
    padding-left: 1rem;
  }
}

.modalHeadPopup {
  .modal-title {
    font-weight: bold;
  }

  .btn-close {
    position: relative;
  }

  .btn-close::after {
    content: "x";
    position: absolute;
    left: .55rem;
    top: .1rem;
    font-weight: bold;
    font-family: sans-serif;
    font-size: 18px;
  }
}

.jobDetailDiv {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgba($color: #000000, $alpha: .2);
  padding: 0 0 2rem;

  .jobDetailInner {
    width: 80%;
    margin: -1.5rem auto 2rem;
    background: #fff;
    box-shadow: 0 3px 10px rgba($color: #000000, $alpha: .2);
    border-radius: 5px;
    padding: 1rem 0 3rem;

    table {
      width: 70%;
      margin: 0 auto;
    }

    h1 {
      font-size: 2rem;
      font-weight: bold;
    }
  }

  .successDiv {
    background: #53a248;
    text-align: center;
    font-size: 2rem;
    padding: 2rem 2rem 3rem;
    color: #fff !important;
  }

  .errorDiv {
    background: #f44336;
    text-align: center;
    font-size: 2rem;
    padding: 2rem 2rem 3rem;
    color: #fff !important;
  }

  .btn {
    background: #00427E !important;
  }

  .unsuccessDiv {
    background: #9da248;
    text-align: center;
    font-size: 2rem;
    padding: 2rem 2rem 3rem;
    color: #fff !important;
  }
}

.main-div th {
  font-weight: bold;
  font-size: 16px;
}

.main-div table {
  border: 1px solid;
}