@import "variables.css";
/* FONT FACE */
@media print {
  html,
  body {
    display: none; /* hide whole page */
  }
}
@font-face {
  font-family: "whitneybook";
  src: url("../assets/fonts/whitneybook-webfont.woff2") format("woff2"),
    url("../assets/fonts/whitneybook-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "whitneymedium";
  src: url("../assets/fonts/whitneymedium-webfont.woff2") format("woff2"),
    url("../assets/fonts/whitneymedium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "whitneysemibold";
  src: url("../assets/fonts/whitneysemibold-webfont.woff2") format("woff2"),
    url("../assets/fonts/whitneysemibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "libel_suitregular";
  src: url("../assets/fonts/libel-suit-rg-webfont.woff2") format("woff2"),
    url("../assets/fonts/libel-suit-rg-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
html,
body {
  height: 100%;
  background: #f3f5f7 !important;
  font-size: 1rem;
}
html,
body,
#root,
.App {
  padding: 0;
  margin: 0;
  min-height: 100%;
  font-size: var(--fontMedium);
  /* background-color: var(--basicBackgroundColor); */
  color: var(--basicColor);
}
.material-icons{
  font-family: 'Material Icons', sans-serif !important;
}
.link-label {
  text-decoration: underline;
  color: #0093de !important;
}
.bill-copy {
  width: 70%;
  height: 90%;
}
.modal {
  z-index: 11055 !important;
}
iframe {
  display: none !important;
}
*,
button {
  font-family: "whitneybook" !important;
}
.form-control {
  min-height: 40px;
}
.fa {
  font: normal normal normal 14px/1 FontAwesome !important;
}
/* main-container */
.main-container .alert {
  position: absolute;
  bottom: 0;
  right: 0;
}

/* header */
header {
  min-height: 45px;
  padding: 7px 0 0 !important;
  margin-bottom: 0.5rem;
}

/* navbar */
.navbar.scrolling-navbar.top-nav-collapse,
.my-custom-nav-bar {
  background-color: var(--secondaryBackgroundColor);
}

/* footer */
footer.page-footer {
  clear: both;
}

/* buttons */
button,
.btn,
.loadMore {
  background: var(--primaryBackgroundColor) !important;
  color: var(--primaryColor);
  text-transform: capitalize !important;
}

/* inputs */
input[type="text"],
input[type="password"],
input[type="number"] select {
  background: var(--basicBackgroundColor) !important;
  border-radius: 0 !important;
}
select.form-control,
input[autocomplete] {
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")
    98% center no-repeat !important;
  background-size: 0.85rem !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--basicBackgroundColor) inset !important;
}

label {
  text-align: left;
}
label[for="email"]:after,
label[for="username"]:after {
  content: " *";
  color: red;
}

a {
  color: var(--anchorColor);
  font-size: var(--fontSmall);
}
.preHead {
  display: none;
}
.auth-layout {
  background: #fff;
}
.formBox label {
  font-size: 14px;
  font-weight: normal !important;
  color: #5d5d5d !important;
  text-align: left;
  top: -5px;
  left: 5px;
}
input {
  border: 1px solid #b4b4b4;
  padding: 5px;
}
input[type="button"],
button,
.btn-primary {
  background: #00427e !important;
  color: #fff !important;
  margin: 0 10px;
  display: inline-block;
}
.form-actions {
  justify-content: space-around;
  align-items: center;
}
.form-actions button {
  margin: 10px;
}

.filterBox {
  background: #fff;
  border-radius: 5px;
  margin: 46px 0 1rem 15px;
  padding: 30px 21px 0px 14px;
}
.filterBox h4 {
  color: #000;
  margin-bottom: 20px;
  font-size: 18px;
}
.filterBox select {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.filterBox select.form-control {
  vertical-align: top;
  height: 20px !important;
  max-height: 50px !important ;
}
.filterBox select.form-control option {
  max-height: 20px !important;
}
.rightCol p {
  margin-bottom: 20px;
}
.rightCol p label {
  display: block;
  margin-bottom: 2px;
  color: #5d5d5d;
  font-size: 12px;
}
.rightCol .inputBox {
  width: 100%;
  border-radius: 5px;
}
.rightCol .filterSec {
  font-size: 14px;
  font-weight: bold;
}
.rightCol .filterSec .MuiSvgIcon-root {
  font-size: 14px;
  margin-top: -2px;
  margin-right: 0;
}

.leftCol .makeStyles-activityLogo-4 {
  background: #e9f4fc;
  color: #00427e;
}

.cardBoxAcivity .makeStyles-activityLogo-4 {
  background: transparent;
  padding: 0 !important;
}
.cardBoxAcivity .makeStyles-activityLogo-4 .boxName {
  background: #e9f4fc;
  width: 100px;
  height: 100px;
  padding: 0;
  font-size: 37px;
  line-height: 100px;
  border-radius: 4px;
  margin: 5px;
}

.activityList {
  border: 0;
}
.activityList .MuiBox-root {
  padding: 0;
}
.MuiGrid-container {
  border: 0;
}
.MuiPagination-root {
  margin: 20px 0;
  float: left;
}
.activityList h2 {
  color: #000;
  font-size: 20px;
  margin: 0 0 10px;
  padding: 0;
}
.buttonBox {
  padding: 12px 12px 0;
}
.icn svg {
  background: #f3f5f7;
  color: #000;
  margin: 0 10px;
  border-radius: 50%;
  padding: 8px 5px;
  font-size: 30px;
}
.icn.rightIcn svg {
  background: none !important;
  left: -10px;
  cursor: pointer;
}
.icn .MuiSvgIcon-root {
  position: relative;
  left: 1px;
  top: -2px;
  font-size: 2.65rem;
  color: #00427e;
  margin: 0 0.35rem;
}
.icn.rightIcn {
  width: 25px;
  margin: 0;
  padding: 0;
  display: inline-block;
}
.activityList .loadMore {
  float: left;
  margin: 1.2rem 0 0.75rem;
}
.btnBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.breadcrumWrapper {
  margin-bottom: 10px;
  font-size: 14px;
}
.breadcrumWrapper li:nth-child(1) {
  margin-left: 20px;
}
.breadcrumWrapper a {
  font-weight: bold;
  color: #000;
  font-size: 14px;
}
.breadcrumWrapper li:last-child a {
  color: #00427e;
}
.icnFilter {
  margin-right: 5px;
}
.boxHead {
  font-size: 16px;
  color: #000;
}
input.form-control,
input.inputBox {
  border: 1px solid #ccc;
  border-radius: 4px !important;
}
input.form-control[autocomplete] {
  font-weight: 600;
}
select.form-control {
  border-radius: 4px !important;
  font-weight: 600;
}

.btn {
  border-radius: 4px !important;
  font-size: 1rem !important;
  line-height: 20px !important;
  padding: 5px 20px !important;
}
.btn.btn-outline-danger {
  background: #0093de !important;
  color: #fff !important;
  border: 0 !important;
}
.btn-outline-primary {
  background: #000 !important;
  color: #fff !important;
}

.topMenus {
  font-size: 14px;
  margin-right: 15px;
  position: relative;
  z-index: 2;
}
.topMenus .btn {
  margin: 0;
  font-size: 12px;
  text-transform: capitalize;
}
.topMenus .rightPanel {
  text-align: right;
}
.topMenus select,
.topMenus input {
  padding: 5px;
  border: 1px solid #ccc;
  margin: 0 10px;
  font-weight: bold;
  border-radius: 4px !important;
}

.labelGroup span {
  color: #000 !important;
}
.labelGroup label {
  color: #5d5d5d !important;
}
.labelGroup {
  border-top: 1px solid #f3f5f7;
  padding-top: 7px;
  color: #000;
}
.labelGroup .MuiGrid-item {
  border-right: 1px solid #f3f5f7;
}
.labelGroup .MuiGrid-item:nth-child(4),
.labelGroup .MuiGrid-item:nth-child(5) {
  border: 0;
}
.css-bshv44-MuiButtonBase-root-MuiListItem-root.Mui-selected {
  background-color: #043560 !important;
  border-left: 5px solid #ef7a22 !important;
}

body .fi-file-uploader-wrapper .file-validation {
  background: transparent;
  color: #ff3547;
  font-size: 12px;
  padding: 0;
  font-weight: normal;
}
body .fi-file-uploader-wrapper .empty-files {
  text-align: left;
}
.internet-connection-status {
  position: fixed;
  z-index: 9999;
  width: 100%;
  text-align: center;
  padding: 5px;
}
.internet-connection-status.online {
  background-color: rgb(36, 182, 68);
}
.internet-connection-status.offline {
  background-color: red;
}

.noMoreDiv {margin-top:1.5rem;}

@media only screen and (max-width:767px) {
  .icn.rightIcn svg {left:20px!important;cursor: pointer;}
  .MuiDrawer-paper li:first-child { display: flex; justify-content: space-between;}
  .MuiBreadcrumbs-root {padding: 0!important; margin: 0!important;}
.formInfo {padding: 0!important; font-size: 16px!important;}
  .agent-container.content.col-md-10 {margin-top:70px!important;}
  .form-actions {z-index: 99;}
.agent-container {margin-top:65px!important;}
.rightPanel {text-align: left!important;}
.rightPanel  select {margin-left: 0;}
.container.dynamicForm {padding-bottom: 50px; max-width:98%;}
.listingCard br {display: none;}
.listingCard label {min-width:45%;}
.listingCard .makeStyles-activityLogo-40 {display: none;}

.loader-overlay .loader {left:35% !important}
/* Activity List Mobile */
.activityList .branchName {padding-left:0 !important;}
.activityList .MuiPaper-rounded {min-height:310px;}
.activityList .buttonBox {position:absolute; top:250px; width:100%; left:-7.5rem;}
.activityList .cardTop {left:85px !important}
.cardBoxAcivity .makeStyles-activityLogo-4 .boxName {width:65px; height:65px; line-height:65px;}
.activityList .labelGroup:last-child {border-bottom:1px solid #F3F5F7;}
body .agent-layout .rowDiv {margin:0;}
.filterBox  {height:100vh;position: fixed;top: 0;z-index: 1200;overflow: scroll;}

}

.addItem {position: fixed; bottom:4rem; right:20px; border-radius: 50%; font-size: 40px; border:0; background: #000!important; font-weight: bold; height:50px; line-height: 45px; width: 50px; z-index: 9;}
.btnHome {border:0; cursor: pointer;}

.MuiTabs-flexContainer button {line-height: 30px; font-size: 14px; padding:1px 25px!important; margin: 0!important; min-height: auto;}
.MuiTabs-flexContainer button:first-child  {border-radius: 10px 0 0 10px}
.MuiTabs-flexContainer button:last-child {background: #fff!important; color: #000!important; border-radius: 0 10px 10px 0}
.MuiTabs-flexContainer .Mui-selected {background: #0093DE!important; border-color:#0093DE !important; color: #fff!important;}

.tabBox {margin-top:7rem; position: relative; z-index:2;}
.tabBox .MuiTab-textColorPrimary {background:transparent !important; color:#000 !important; border-radius:50px;}
.tabBox .MuiTab-textColorPrimary.Mui-selected {background:#0093DE !important; color: #fff !important; margin-left:6px !important;}
.tabBox .MuiButtonBase-root.Mui-disabled {background:#ccc !important;}
.tabBox .MuiTabs-flexContainer {display:inline-block; border-radius:50px; background:transparent;}
.tabBox button.MuiButtonBase-root {border-radius:50px; background:#fff !important; border:1px solid #ccc; margin-right:1rem !important;}
.tabBox .MuiTabs-indicator {display:none;}

.pageHead {color:#00427E; font-weight:bold; margin-bottom:10px; padding:0 15px;}
.formInfo {padding:0 20px 0 20px; margin:15px 0; display:block; font-size:14px;}
.text-danger {font-size: 12px;}

.agent-layout .rowDiv {margin:0 10px 0 225px;}
.menuDrp .MuiPaper-root {margin-top:40px; right:1.5rem; left:auto !important}
.menuDrp .MuiPaper-root .MuiList-root li {display:block;}
.mobMenu .mobListMenu .MuiTypography-root {float:left; font-size:14px; width:140px;}
.mobMenu .mobListMenu span.MuiTypography-root {position: relative; top:-.15rem; margin-right:.5rem; width:15px;}
.mobMenu .mobListMenu span.MuiTypography-root svg {font-size:15px}

.deskMenu .MuiTypography-root {float:left; margin-right:.5rem; color:#fff; font-size:14px; width:130px;}
.deskMenu .eOiCTH:hover {border:0; color:#fff;}
.deskMenu span.MuiTypography-root {position: relative; top:-.15rem; margin-right:.5rem; width:20px;}
.deskMenu span.MuiTypography-root svg {font-size:15px}
.deskMenu a {padding:0 0 0 2rem; font-size:13.5px;}
.deskMenu a:hover {background:#0093DE; color:#fff;}

 .mobMenu .mobLogo {background-color:#fff;}
 .mobMenu .logoutButton .MuiButtonBase-root {margin-top:1rem; background-color:#000000; border-radius:0 50px 50px 0; width:120px; padding:.35rem 0 .35rem .65rem;}
 .mobMenu .logoutButton svg {color:#fff; margin-right:.5rem;}
 .mobMenu .logoutButton .MuiTypography-root {color:#fff; font-size:14px; font-weight:bold;}
 .profileMobile {border-bottom:1px dotted #00427E; font-size:16px; padding:40px 20px 20px; position: relative; min-height:130px;}
 .profileMobile .leftBox {width:55px; height:55px; display:block; background-color:#00427E; border-radius:10px; float:left; font-size:20px; color:#fff; text-align:center; font-weight:bold; line-height:55px; position:relative;}
 .profileMobile .closeBtn {background:transparent !important; position:absolute; right:.25rem; top:.25rem;}
 .profileMobile .closeBtn svg {color:#000; font-size:14px;}
 .profileMobile .leftBox .onlineGreen {border-radius:50px; background-color:#08C400; border:1px solid #fff; width:8px; height:8px; position:absolute; top:.45rem; right:.45rem}
 .profileMobile .profText {width:63%; float:right; color:#00427E; font-weight:bold; margin-top:.2rem;}
 .profileMobile .profText span {font-size:8.5px; display:block; color:#000; font-weight:normal; margin-top:.5rem;}


.agent-container {margin-top:80px !important;}
.hdTop {box-shadow:none !important; background:#F3F5F7 !important;}

.hdTopDiv .srchBox {border-radius:5px; background-color:#fff;}
.hdTopDiv .srchBox .MuiInputBase-root {border:1px solid rgba(93, 93, 93, .5); border-radius:5px; height:35px;}

.hdTopDiv .srchBox svg {color:#000; position:relative; z-index:1; float:right;}
.hdTopDiv .srchBox input {border-radius:5px !important; height:17px; color:#000; padding:8px 40px 8px 8px !important}
.hdTopDiv .srchBox .srchBtn {border-left:1px solid #5D5D5D; right:0; padding:0 .5rem}

.hdTopDiv .notifiDiv {background:transparent !important;}
.hdTopDiv .notifiDiv svg {color:#00427E !important}
.hdTopDiv .accoDiv {background:#000 !important; border-radius:5px; margin-left:1rem; padding:.3rem; margin-right:-.35rem;}
.hdTopDiv .drpMenuIcon {background:transparent !important;}
.hdTopDiv .drpMenuIcon svg {color:#000000 !important}

.headMobile {display:none; position:absolute; width:100%; left:0; top:0;}
.loadMore {margin:1rem 0 !important;}

  @media only screen and (min-width:1024px) and (max-width:1919px) {
.icn em {display: none;}
  }
.login-header {display:none !important;}
.hdTop {box-shadow:0 2px 5px 0 rgba(0 0 0 / 16%), 0 2px 10px 0 rgba(0 0 0 / 12%) !important;}

.postHead {display:none;}

@media only screen and (max-width:767px) {
.hdTop {padding-top:.25rem; background:#00427E !important; }
.hdSrchBx {background:#00427E; padding:2rem 0 2.5rem; margin-top:-.35rem}
.hdSrchBx .MuiInputBase-root {width:100%; margin-right:0; height:40px !important;}
.hdSrchBx .srchBox {margin-right:0;}
.hdSrchBx input {width:100%; padding:8px;}
}
  @media only screen and (max-width:600px) {
    .createLead .formBox {padding-bottom:5rem;}
    .agent-layout {width:calc(100% - 10px)}
    .hdTop {padding-top:.25rem; background:#00427E !important; }
.hdTopDiv .notifiDiv svg {color:#fff !important}
    header {background:var(--bg-color-mobHead) !important; height:60px; padding:0;}
    header .MuiButtonBase-root {position: relative; z-index:10; padding:0;}
    header .MuiTypography-root {margin-left:.85rem;}
    .postHead .fixed-top {height:50px}
    .navbar.scrolling-navbar {padding:0 !important;}
    .brand-logo {position:absolute; left:0; top:0;}

    .headMobile {display:none; margin:1.1rem 0 0}
    .headMobile .hdLt {float:left; width:40%; margin:0 0 0 2.3rem;}
    .headMobile .hdLt .MuiButtonBase-root {margin-left:-10px; background:transparent !important;}
    .headMobile .MuiSvgIcon-root {width:35px}
    .headMobile .hdRt {float:right; width:40%; text-align:right; margin-right:.5rem;}


    .activityList h2 {min-height:40px;}
    .activityList .loadMore, .leadList .loadMore {width:100%; padding:.80rem 0 !important; margin-left:0 !important;}
    .breadcrumWrapper li:nth-child(1) {margin-left:13px;}
    .formInfo {font-weight:bold;}

    .leadList .filterBox, .activityList .filterBox {margin-left:0; margin-top:33px !important; width:100%;}
    .filterBox {margin:0 0 35px 0;}
    .addItem {bottom:1rem;}
    .tabBox {margin-top:4rem;}
    .tabBox .MuiTabs-scroller {overflow-x:auto !important;}
    .topMenus .smsBox select {width:5.9rem;}
    .topMenus .rightPanel {text-align:right !important;}
  }

  .dynamic-form .form-actions button {min-width:90px; font-size:14px!important; text-transform: uppercase!important;}
.noMoreDiv {
  margin-top: 1.5rem;
}

@media only screen and (max-width: 767px) {
  .icn.rightIcn svg {
    left: 20px !important;
    cursor: pointer;
  }
  .MuiDrawer-paper li:first-child {
    display: flex;
    justify-content: space-between;
  }
  .MuiBreadcrumbs-root {
    padding: 0 !important;
    margin: 0 !important;
  }
  .formInfo {
    padding: 0 !important;
    font-size: 16px !important;
  }
  .agent-container.content.col-md-10 {
    margin-top: 70px !important;
  }
  .form-actions {
    z-index: 99;
  }
  .agent-container {
    margin-top: 65px !important;
  }
  .rightPanel {
    text-align: left !important;
  }
  .rightPanel select {
    margin-left: 0;
  }
  .container.dynamicForm {
    padding-bottom: 50px;
    max-width: 98%;
  }
  .listingCard br {
    display: none;
  }
  .listingCard label {
    min-width: 45%;
  }
  .listingCard .makeStyles-activityLogo-40 {
    display: none;
  }

  .loader-overlay .loader {
    left: 35% !important;
  }
  /* Activity List Mobile */
  .activityList .branchName {
    padding-left: 0 !important;
  }
  .activityList .MuiPaper-rounded {
    min-height: 310px;
  }
  .activityList .buttonBox {
    position: absolute;
    top: 250px;
    width: 100%;
    left: -7.5rem;
  }
  .activityList .cardTop {
    left: 85px !important;
  }
  .cardBoxAcivity .makeStyles-activityLogo-4 .boxName {
    width: 65px;
    height: 65px;
    line-height: 65px;
  }
  .activityList .labelGroup:last-child {
    border-bottom: 1px solid #f3f5f7;
  }
  body .agent-layout .rowDiv {
    margin: 0;
  }
  .filterBox {
    height: 100vh;
  }
}

.addItem {
  position: fixed;
  bottom: 4rem;
  right: 20px;
  border-radius: 50%;
  font-size: 40px;
  border: 0;
  background: #000 !important;
  font-weight: bold;
  height: 50px;
  line-height: 45px;
  width: 50px;
  z-index: 9;
}
.btnHome {
  border: 0;
  cursor: pointer;
}

.MuiTabs-flexContainer button {
  line-height: 30px;
  font-size: 14px;
  padding: 1px 25px !important;
  margin: 0 !important;
  min-height: auto;
}
.MuiTabs-flexContainer button:first-child {
  border-radius: 10px 0 0 10px;
}
.MuiTabs-flexContainer button:last-child {
  background: #fff !important;
  color: #000 !important;
  border-radius: 0 10px 10px 0;
}
.MuiTabs-flexContainer .Mui-selected {
  background: #0093de !important;
  border-color: #0093de !important;
  color: #fff !important;
}

.tabBox {
  margin-top: 7rem;
  position: relative;
  z-index: 2;
}
.tabBox .MuiTab-textColorPrimary {
  background: transparent !important;
  color: #000 !important;
  border-radius: 50px;
}
.tabBox .MuiTab-textColorPrimary.Mui-selected {
  background: #0093de !important;
  color: #fff !important;
  margin-left: 6px !important;
}
.tabBox .MuiButtonBase-root.Mui-disabled {
  background: #ccc !important;
}
.tabBox .MuiTabs-flexContainer {
  display: inline-block;
  border-radius: 50px;
  background: transparent;
}
.tabBox button.MuiButtonBase-root {
  border-radius: 50px;
  background: #fff !important;
  border: 1px solid #ccc;
  margin-right: 1rem !important;
}
.tabBox .MuiTabs-indicator {
  display: none;
}

.pageHead {
  color: #00427e;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 0 15px;
}
.formInfo {
  padding: 0 20px 0 20px;
  margin: 15px 0;
  display: block;
  font-size: 14px;
}
.text-danger {
  font-size: 12px;
}

.agent-layout .rowDiv {
  margin: 0 10px 0 225px;
}
.menuDrp .MuiPaper-root {
  margin-top: 40px;
  right: 1.5rem;
  left: auto !important;
}
.menuDrp .MuiPaper-root .MuiList-root li {
  display: block;
}
.mobMenu .mobListMenu .MuiTypography-root {
  float: left;
  font-size: 14px;
  width: 140px;
}
.mobMenu .mobListMenu span.MuiTypography-root {
  position: relative;
  top: -0.15rem;
  margin-right: 0.5rem;
  width: 15px;
}
.mobMenu .mobListMenu span.MuiTypography-root svg {
  font-size: 15px;
}

.deskMenu .MuiTypography-root {
  float: left;
  margin-right: 0.5rem;
  color: #fff;
  font-size: 14px;
  width: 130px;
}
.deskMenu span.MuiTypography-root {
  position: relative;
  top: -0.15rem;
  margin-right: 0.5rem;
  width: 20px;
}
.deskMenu span.MuiTypography-root svg {
  font-size: 15px;
}

.mobMenu .mobLogo {
  background-color: #fff;
}
.mobMenu .logoutButton .MuiButtonBase-root {
  margin-top: 1rem;
  background-color: #000000;
  border-radius: 0 50px 50px 0;
  width: 120px;
  padding: 0.35rem 0 0.35rem 0.65rem;
}
.mobMenu .logoutButton svg {
  color: #fff;
  margin-right: 0.5rem;
}
.mobMenu .logoutButton .MuiTypography-root {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}
.profileMobile {
  border-bottom: 1px dotted #00427e;
  font-size: 16px;
  padding: 40px 20px 20px;
  position: relative;
  min-height: 130px;
}
.profileMobile .leftBox {
  width: 55px;
  height: 55px;
  display: block;
  background-color: #00427e;
  border-radius: 10px;
  float: left;
  font-size: 20px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  line-height: 55px;
  position: relative;
}
.profileMobile .closeBtn {
  background: transparent !important;
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
}
.profileMobile .closeBtn svg {
  color: #000;
  font-size: 14px;
}
.profileMobile .leftBox .onlineGreen {
  border-radius: 50px;
  background-color: #08c400;
  border: 1px solid #fff;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 0.45rem;
  right: 0.45rem;
}
.profileMobile .profText {
  width: 63%;
  float: right;
  color: #00427e;
  font-weight: bold;
  margin-top: 0.2rem;
}
.profileMobile .profText span {
  font-size: 8.5px;
  display: block;
  color: #000;
  font-weight: normal;
  margin-top: 0.5rem;
}

.agent-container {
  margin-top: 80px !important;
}
.hdTop {
  box-shadow: none !important;
  background: #f3f5f7 !important;
}

.hdTopDiv .srchBox {
  border-radius: 5px;
  background-color: #fff;
}
.hdTopDiv .srchBox .MuiInputBase-root {
  border: 1px solid rgba(93, 93, 93, 0.5);
  border-radius: 5px;
  height: 35px;
}

.hdTopDiv .srchBox svg {
  color: #000;
  position: relative;
  z-index: 1;
  float: right;
}
.hdTopDiv .srchBox input {
  border-radius: 5px !important;
  height: 17px;
  color: #000;
  padding: 8px 40px 8px 8px !important;
}
.hdTopDiv .srchBox .srchBtn {
  border-left: 1px solid #5d5d5d;
  right: 0;
  padding: 0 0.5rem;
}

.hdTopDiv .notifiDiv {
  background: transparent !important;
}
.hdTopDiv .notifiDiv svg {
  color: #00427e !important;
}
.hdTopDiv .accoDiv {
  background: #000 !important;
  border-radius: 5px;
  margin-left: 1rem;
  padding: 0.3rem;
  margin-right: -0.35rem;
}
.hdTopDiv .drpMenuIcon {
  background: transparent !important;
}
.hdTopDiv .drpMenuIcon svg {
  color: #000000 !important;
}

.headMobile {
  display: none;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}
.loadMore {
  margin: 1rem 0 !important;
}

@media only screen and (min-width: 1024px) and (max-width: 1919px) {
  .icn em {
    display: none;
  }
}
.login-header {
  display: none !important;
}
.hdTop {
  box-shadow: 0 2px 5px 0 rgba(0 0 0 / 16%), 0 2px 10px 0 rgba(0 0 0 / 12%) !important;
}

.postHead {
  display: none;
}

@media only screen and (max-width: 767px) {
  .hdTop {
    padding-top: 0.25rem;
    background: #00427e !important;
  }
  .hdSrchBx {
    background: #00427e;
    padding: 2rem 0 2.5rem;
    margin-top: -0.35rem;
  }
  .hdSrchBx .MuiInputBase-root {
    width: 100%;
    margin-right: 0;
    height: 40px !important;
  }
  .hdSrchBx .srchBox {
    margin-right: 0;
  }
  .hdSrchBx input {
    width: 100%;
    padding: 8px;
  }
}
@media only screen and (max-width: 600px) {
  .createLead .formBox {
    padding-bottom: 5rem;
  }
  .agent-layout {
    width: calc(100% - 10px);
  }
  .hdTop {
    padding-top: 0.25rem;
    background: #00427e !important;
  }
  .hdTopDiv .notifiDiv svg {
    color: #fff !important;
  }
  header {
    background: var(--bg-color-mobHead) !important;
    height: 60px;
    padding: 0;
  }
  header .MuiButtonBase-root {
    position: relative;
    z-index: 10;
    padding: 0;
  }
  header .MuiTypography-root {
    margin-left: 0.85rem;
  }
  .postHead .fixed-top {
    height: 50px;
  }
  .navbar.scrolling-navbar {
    padding: 0 !important;
  }
  .brand-logo {
    position: absolute;
    left: 0;
    top: 0;
  }

  .headMobile {
    display: none;
    margin: 1.1rem 0 0;
  }
  .headMobile .hdLt {
    float: left;
    width: 40%;
    margin: 0 0 0 2.3rem;
  }
  .headMobile .hdLt .MuiButtonBase-root {
    margin-left: -10px;
    background: transparent !important;
  }
  .headMobile .MuiSvgIcon-root {
    width: 35px;
  }
  .headMobile .hdRt {
    float: right;
    width: 40%;
    text-align: right;
    margin-right: 0.5rem;
  }

  .activityList h2 {
    min-height: 40px;
  }
  .activityList .loadMore,
  .leadList .loadMore {
    width: 100%;
    padding: 0.8rem 0 !important;
    margin-left: 0 !important;
  }
  .breadcrumWrapper li:nth-child(1) {
    margin-left: 13px;
  }
  .formInfo {
    font-weight: bold;
  }

  .leadList .filterBox,
  .activityList .filterBox {
    margin-left: 0;
    margin-top: 33px !important;
    width: 100%;
  }
  .filterBox {
    margin: 0 0 35px 0;
  }
  .addItem {
    bottom: 1rem;
  }
  .tabBox {
    margin-top: 4rem;
  }
  .tabBox .MuiTabs-scroller {
    overflow-x: auto !important;
  }
  .topMenus .smsBox select {
    width: 5.9rem;
  }
  .topMenus .rightPanel {
    text-align: right !important;
  }
}

.dynamic-form .form-actions button {
  min-width: 90px;
  font-size: 14px !important;
  text-transform: uppercase !important;
}

/*

  .collapse::after {
    content: '';
    position: absolute;
    right: 0;
    top: 20px;
    display: block;
    border-left: 3px solid #000;
    border-top: 3px solid #000;
    width: 12px;
    height: 12px;
    float: right;
    transform: translate(50%, 50%) rotate(-45deg);
  }

  .collapse.show::after {
    transform: translate(50%, -50%) rotate(-45deg);
  } */

.react-switch-bg {
  border: 1px solid #ccc;
  border-radius: 10px;
}
.form-title {
  color: #00427e !important;
  font-family: "whitneysemibold" !important;
}

.radioBoxDiv {
  font-size: 16px;
  line-height: 25px;
  background: #fff !important;
}
.radioBoxDiv div {
  color: #f2f2f2 !important;
}

input.form-control[role="combobox"] {
  margin-bottom: 0 !important ;
}
.autocomple-menu-wrapper {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 0 0 5px 5px;
  padding: 5px;
  border-top: 0;
}

.saveAsDraftDiv {
  padding: 1rem 1rem 0;
  font-size: 14px !important;
}
.saveAsDraftDiv .draftHead {
  font-size: 16px !important;
  font-weight: bold;
}
.saveAsDraftDiv .MuiGrid-root {
  margin-bottom: 0.5rem;
}
.saveAsDraftDiv .MuiGrid-item svg {
  float: right;
}
.saveAsDraftDiv .btn {
  width: 45%;
  padding: 7px 10px !important;
}
.saveAsDraftDiv .cancelDraftBtn {
  background: #00427e !important;
}
.disabled {
  background: #f2f2f2 !important;
  color: #7e7e7e !important;
}
.disabled * {
  color: #7e7e7e !important;
}
.autocomplete-wrapper .autocomple-menu-wrapper {
  max-height: 300px !important;
  overflow-y: auto;
  width: 100%;
}
.commonCalendar .cardListHeight .MuiPaper-root {
  min-width: auto;
}
.commonCalendar .editBtn .MuiButtonBase-root {
  background: #000 !important;
}
.commonCalendar .editBtn svg {
  font-size: 36px;
  font-weight: bold;
}

@media only screen and (max-width: 850px) {
  .hdTopDiv .notifiDiv {
    background: #00427e !important;
  }
  .hdTopDiv .notifiDiv svg {
    color: #fff !important;
  }
  .addItem {
    bottom: 1.5rem;
  }

  /* Activity List Mobile */
  /* .filterBox  {height:100vh;position: fixed;top: 0;z-index: 1200;overflow: scroll;} */
}
@media only screen and (max-width: 800px) {
  .agent-layout .rowDiv {
    margin: 0 15px 0 5px;
  }
  .sidebar .MuiDrawer-root {
    display: none;
  }
  .sidebar .MuiPaper-root {
    margin-left: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .leadFilterBox .icnFilterBox .MuiButtonBase-root {
    right: 23px !important;
  }
  .leadFilterBox .icnFilterBox svg {
    font-size: 25px;
  }
}

@media only screen and (max-width: 340px) {
  .agent-layout {
    width: calc(100% - 0);
  }
  .topMenus .selectBox {
    padding: 1px 7px 0 2px !important;
    margin: 1px 0 0;
  }
  .topMenus .selectBox span {
    font-size: 11px;
  }
  .topMenus .smsBox select {
    font-size: 11px;
    margin: 0 5px;
    padding: 6px 4px;
  }
  .topMenus .rightPanel {
    font-size: 12px;
  }
}

.myDashboardDiv li {
  position: relative;
}
.myDashboardDiv .closeBtn {
  position: absolute;
  top: 0;
  right: 1rem;
  font-weight: bold;
  font-size: 16px;
}
.myDashboardDiv .MuiButtonBase-root.MuiListItem-root:first-child {
  border-top: 1px dotted #00427e !important;
}
.myDashboardDiv .MuiCheckbox-root {
  border: 0 !important;
}
.myDashboardDiv button[type="submit"] {
  background: #000 !important;
  padding: 7px 50px !important;
  margin: 2rem 0 0 2rem;
  float: left;
}
.myDashboardDiv .resetClick {
  float: left;
  margin: 2.3rem 0 0 1.5rem;
  font-size: 16px;
}
.myDashboardDiv .resetIcon {
  font-size: 16px;
  float: left;
  margin: 0.3rem 0.5rem 0 0;
}
.MuiAutocomplete-root.form-control {
  height: auto !important;
}

.paginatorDiv {clear:both; margin:1rem 0;}
.paginatorDiv button {color:#000 !important; background:transparent !important; border:0; margin:0 .5rem}
.paginatorDiv button.disabled {color:#fff !important; background:#0093DE !important; border-radius:50px; width:25px; height:25px;}
.paginatorDiv button.disabled:first-child, .paginatorDiv button:first-child, .paginatorDiv button:last-child {background:#b4b4b4 !important; color:#000 !important; width:25px; height:25px; border-radius:50px;}

.filterBox .MuiAutocomplete-endAdornment .MuiButtonBase-root {width:25px; height:25px; margin:.25rem 0 0 .25rem;}

.trainingAttendeeSec {margin:2rem 0 0 !important;}
.trainingAttendeeSec .form-group {margin-bottom:.4rem;}
.trainingAttendeeSec .form-group label {display:inline-block; width:70px;}
.trainingAttendeeSec .form-group .form-control {display:inline-block; width:130px}
.trainingAttendeeSec .form-group .text-danger {position:absolute; bottom:10px; left:220px; width:230px; text-align:left;}

/* Filter drawer Close Btn */
.rs-drawer-body-close{
  display: none;
}

.form-control:disabled {
  background-color: #e9ecef !important;
  opacity: 1;
}