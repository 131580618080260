.pehlaKadamQuestionSec {margin:7rem 1rem 1rem; font-size:1rem;
    .clearfix {width:100%; clear:both; overflow:hidden; font-size:0; height:0; display:block;}
    .btn-primary {background:#00427E !important;}    
    .MuiGrid-root {display:inline-block; min-height:100px; max-width:100%;
        .MuiGrid-root:first-child {padding:0; float:left;
            .subTitle {display:block;}
            .MuiPaper-root {float:left; width:4.5rem; height:4.5rem;  background:transparent; margin:0 1rem 0 0;
                h2.MuiTypography-root {font-weight:bold;}
            }       
        }
    }
    .MuiPaper-root {width:100%; margin-bottom:1rem;}
    .MuiPaper-root::before {content:none;}
    .accordionlist {width:100%; max-width:100%;
        .MuiPaper-root {border:0; background:transparent; box-shadow:none;}
        .MuiAccordionDetails-root {padding:0;}
        .MuiFormControl-root, .MuiList-root {width:100%; max-width:100%; margin:0; padding:0;}
        .MuiPaper-root:nth-child(1) {box-shadow:none; padding:0; background:transparent;}
        .MuiList-root {margin:0; padding:0;}
        .MuiList-root li {padding:1rem 1rem .5rem; margin:0; width:100%; display:block; clear:both;
            .MuiListItemText-root {float:left; width:85%;}
            .MuiTypography-root {font-weight:bold;}
            .MuiSvgIcon-root {margin:.70rem .5rem 0 0; float:left; font-size:.5rem}
        }
        .MuiFormGroup-root {padding:0 1rem; float:right; width:100%;}
        .MuiButtonBase-root.MuiAccordionSummary-root {background:#DFE6ED; overflow:hidden; border-radius:5px; min-height:48px; width:100%; padding:0 1rem;
            .MuiAccordionSummary-content {float:left; 
                .MuiTypography-root {font-weight:bold; color:#00427E;}
            }
            .MuiAccordionSummary-expandIconWrapper {float:right;}
            .MuiAccordionSummary-content {margin:0; padding:0;}
        }
    }
    .radioBoxDiv {border:1px solid #B4B4B4; height:28px !important; border-radius:25px !important; float:none; width:auto; display:inline-block; max-width:83px; margin-bottom:1rem;
        .react-switch-handle {width:23px !important; border-radius:25px !important; height:24px !important; background:#00427E !important; z-index:0 !important; margin:1px 0px 1px 1px}
        .MuiSwitch-root { padding:0; height:1.7rem; width:5.5rem; margin:1px 0 0 13px}
        .MuiSwitch-switchBase {padding:0;}
        .MuiSwitch-switchBase.Mui-checked {transform:translateX(40px);}
        
        .MuiSwitch-track {background:transparent !important; z-index:1;}
        .MuiSwitch-track::after {top:8px; opacity:1;}
        .MuiSwitch-track::before { top:8px; opacity:1;}
        .MuiSwitch-thumb {border-radius:10px; width:2.5rem; height:1.6rem; background:#00427E;}
        .switchChecked {text-align:left; padding-left:4px;}
        .switchUnChecked {text-align:right; padding-right:4px;}
        .MuiSwitch-root .MuiSwitch-track {opacity:.65 !important;}
        .Mui-disabled {cursor:no-drop;}
    }
    .historyDiv {
        .MuiGrid-root, .MuiPaper-root {width:100% !important; min-height:auto; height:auto !important;}
        .activityDetailsHead {background:#E9F4FC !important; height:auto !important; padding:1rem}
        .MuiTimeline-root {margin-left:.75rem;}
        .MuiTimeline-root li {font-weight:bold;}
        .MuiTimelineSeparator-root {top:15px; left:-1.7rem !important; min-height:43px !important;}
        .accordianTitle .status {font-weight:bold; color:#00427E;}
    }
    .btnBottom {min-height:auto;}
    .disabled {cursor: no-drop !important}
    .backWhiteColor {overflow:hidden; background:#fff; padding:0 0 1rem !important; margin:0 0 1rem;}
}

@media only screen and (max-width:767px) {
    .pehlaKadamQuestionSec {margin-bottom:3rem;}
    .btnBottom {position:fixed; width:100%; padding:.5rem 0; bottom:0; left:0; min-height:auto !important; background:#fff;
        .btn {width:45vw !important; margin-left:.6rem;}
    }
    .disabled {cursor: no-drop !important}
}