.createLead, .reAssignLeadDiv {
    margin: 4vh auto 0;
    max-width: 50%;
    padding: 20px 0;

    .form-actions {
        text-align: right;

        button {
            padding: 5px 20px;
            border-radius: 5px;
        }
    }

    .form-title {
        display: none !important;
    }

    h4 {
        padding-left: 10px;
    }

    p {
        color: #000;
    }

    .autocomplete-wrapper {
        margin: 0 !important;
    }

    .MuiAutocomplete-root {height:auto; padding-bottom:0;
        .MuiFormControl-root {margin:-6px -10px 0 -12px; width:calc(100% - -24px); min-height:40px; 
          .MuiOutlinedInput-root {padding-top:5px; padding-bottom:0; }
          .MuiOutlinedInput-notchedOutline {border:0}
        }
        
    }
    .MuiAutocomplete-endAdornment {
        .MuiButtonBase-root {margin-left:.5rem;}
    }
}

.radioBoxDiv {
    float: right;
    border: 1px solid #b4b4b4;
    height: 28px !important;
    border-radius: 25px !important;

    .react-switch-handle {
        width: 43px !important;
        border-radius: 25px !important;
        height: 24px !important;
        background: #00427e !important;
        z-index: 0 !important;
        margin: 1px 0px 1px 1px;
    }

    div {
        z-index: 0 !important;
        background: transparent !important;
        text-align: center;
        opacity: 1 !important;
    }

    div .switchChecked {
        text-align: left;
        padding-left: 4px;
    }

    div .switchUnChecked {
        text-align: right;
        padding-right: 4px;
    }

    .react-switch-bg {
        z-index: 1 !important;
        line-height: 0.65;
        border: 0;
        color: #9e9e9e;
    }
}

.form-actions {
    padding-right: 0 !important;

    .savedraftLink {
        font-size: 14px;
        display: inline-block;
        margin: 5px 15px 0 0;
        color: #000000 !important;
        border: none;
        background-color: transparent !important;
    }

    .btn {
        border-radius: 5px;
        padding: 6px 30px;
    }

    .btn.btn-primary {
        background: #000000 !important;
    }

    .btn.cancelBtn {
        background: #00427e !important;
    }

    .btn:last-child {
        margin-right: 0;
    }
}

.buttonBox button {
    width: 30px !important;
    height: 30px !important;
}

@media only screen and (max-width: 767px) {
    .container.dynamicForm {
        margin: 0;
        padding: 0 0 80px 5px !important;
    }

    .savedraftLink {
        display: none;
    }

    .dynamic-form .form-actions {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #fff;
        padding: 10px;
        z-index: 0;
        button {
            width: 42vw !important;
            line-height: 30px;
            min-width: 120px;
            font-size: 14px !important;
        }
    }

    .createLead, .reAssignLeadDiv {
        max-width: 100%;
        margin: 0;
        padding: 0;
    }
}

.control-Location {
    position: relative;

    input[type="text"] {
        padding-right: 1.5rem;
    }
}

.locationTrigger {
    position: absolute;
    top: 41px;
    right: 10px;

    color: #0093de;
    cursor: pointer;
}

.leadDetails {
    .titleBack {
        background-color: rgb(233, 244, 252);
        color: rgb(0, 66, 126);
        box-shadow: none;
        font-size: 14px;
    }
}

.confirmAlert {
    padding: 1rem;

    .content {
        width: 15rem;
        color: #0B0B0B;
    }

    .header {
        display: flex;

        h6 {
            font-weight: bold;
            font-size: 1.5rem;
            color: #000;
        }

        span {
            cursor: pointer;
            margin-left: 13rem;
            color: #000000;
            font-size: 1.3rem;
            font-weight: bolder;
        }
    }

    .dontSaveBtn {
        width: 10rem;
        height: 2.5rem;
        border-radius: 5px;
        margin-left: 2px;
        background: #00427E;
    }

    .saveBtn {
        margin-left: 2rem;
        width: 10rem;
        height: 2.5rem;
        border-radius: 5px;
        // background-color: #000000;
    }
}

@media only screen and (max-width:350px) {
    .dynamic-form .form-actions button {
        width: 41vw !important;
    }
}
.dynamic-form{margin-bottom: 100px;}